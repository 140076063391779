import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  ScrollView,
} from "react-native";
import Header from "./Header";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import Cars from "./Cars";
import AppBox from "./AppBox";
import FAQs from "./FAQs";
import Footer from "./Footer";
import { useState, useRef } from "react";
import PreQualify from "./PreQualify";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    flexDirection: "column",
    flex: 1,
  },
});

const window = Dimensions.get("window");

/**
 * Page structure
 * 1. Header
 * 2. Hero section
 * 3. How it works
 * 4. Cars
 * 5. About app
 * 6. FAQs
 * 7. Footer
 */

export default function TabletContainer({ carData }) {
  const scrollV = useRef();

  const [carV, setCarV] = useState({});

  const [faqV, setFaqV] = useState({});
  return (
    <View style={styles.container}>
      {false && (
        <Image
          resizeMode="cover"
          style={{
            height: 190,
            width: "70%",
            position: "absolute",
            top: 100,
            right: 0,
            opacity: 0.2,
            borderRadius: 32,
          }}
          source={require("../../assets/images/map2.png")}
        />
      )}
      <View style={{ width: "100%", height: "100%" }}>
        <ScrollView ref={scrollV} showsVerticalScrollIndicator={true}>
          <Hero scrollV={scrollV} carV={carV} />
          <HowItWorks />
          <PreQualify />
          <Cars setCarV={setCarV} carData={carData} />
          <AppBox />
          <FAQs />
          <Footer />
        </ScrollView>
      </View>

      <Header carV={carV} scrollV={scrollV} />
    </View>
  );
}
