import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Icon } from "react-native-elements";
import { Button } from "react-native-paper";
import { TextValues } from "../../constants/Strings";

const styles = StyleSheet.create({
  hero: {
    fontFamily: "Uber-Bold",
    fontSize: 32,
    textAlign: "center",
    maxWidth: 300,
    alignSelf: "center",
    marginTop: 8,
  },
  description: {
    fontFamily: "Uber-Light",
    fontSize: 18,
    marginTop: 16,
    textAlign: "center",
    maxWidth: 300,
    alignSelf: "center",
  },
});

export default function Hero({ scrollV, carV }) {
  return (
    <View
      style={{
        flexDirection: "column",
        paddingHorizontal: 20,
        paddingTop: 90,
      }}
    >
      <View style={{ flexDirection: "column" }}>
        <View style={{ flexDirection: "column" }}>
          <Image
            style={{ height: 200, width: "90%" }}
            resizeMode="contain"
            source={require("../../assets/images/car.png")}
          />
        </View>
        <Text style={styles.hero}>{TextValues.hero}</Text>
        <Text style={styles.description}>{TextValues.heroDescription}</Text>
        <View style={{ flexDirection: "column", marginTop: 32 }}>
          <Button
            color="black"
            icon="car"
            mode="contained"
            style={{ alignSelf: "center" }}
            contentStyle={{ height: 55 }}
            theme={{ roundness: 32 }}
            onPress={() =>
              scrollV.current.scrollTo({
                x: 0,
                y: carV.y + 500,
                animated: true,
              })
            }
          >
            Get my car now
          </Button>
          {false && (
            <Button
              color="green"
              icon="whatsapp"
              contentStyle={{ height: 55 }}
              theme={{ roundness: 32 }}
              style={{ alignSelf: "center", marginTop: 8 }}
              onPress={() => {
                Linking.openURL(TextValues.links.whatsapp);
              }}
            >
              send an inquiry
            </Button>
          )}
        </View>
      </View>
    </View>
  );
}
