import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { Icon } from "react-native-elements";
import { Button } from "react-native-paper";
import { useState } from "react";
import CarList from "./Cars/ListV2";

import { TextValues } from "../../constants/Strings";

const styles = StyleSheet.create({});

export default function Cars({ carData, setCarV }) {
  const [tempData, setTempData] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [currIndex, setCurrIndex] = useState(0);
  const size = 15;

  const search = (query) => {
    if (query == "") {
      setSearchText("");
      setTempData([]);
      return;
    }
    setSearchText(query);
    let filteredData = carData.filter(function (item) {
      return item.name.toLowerCase().includes(query.toLowerCase());
    });
    setTempData(filteredData);
  };
  return (
    <View
      style={{
        flexDirection: "column",
        paddingTop: 64,
      }}
    >
      <View
        style={{
          borderRadius: 16,
          flexDirection: "row",
          alignItems: "center",
          marginHorizontal: 20,
        }}
      >
        <Image
          resizeMode="cover"
          style={{
            width: "50%",
            height: 300,
            borderRadius: 16,
            position: "absolute",
            top: 0,
          }}
          source={require("../../assets/images/pattern.jpg")}
        />
        <View
          style={{
            borderRadius: 16,
            backgroundColor: "black",
            height: 300,
            position: "absolute",
            top: 0,
            width: "50%",
            opacity: 0.9,
          }}
        />
        <View style={{ flexDirection: "column", padding: 32, width: "50%" }}>
          <Text
            style={{ fontFamily: "Uber-Bold", fontSize: 24, color: "white" }}
          >
            {TextValues.carCTA.heading}
          </Text>
          <Text
            style={{
              fontFamily: "Uber-Light",
              fontSize: 18,
              color: "white",
            }}
          >
            {TextValues.carCTA.description}
          </Text>
        </View>

        <Image
          resizeMode="cover"
          style={{
            width: "50%",
            height: 300,
            borderRadius: 16,
          }}
          source={require("../../assets/images/people.jpg")}
        />
      </View>

      <View
        style={{ flexDirection: "column", marginTop: 64, marginHorizontal: 20 }}
      >
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <Text style={{ fontFamily: "Uber-Medium", fontSize: 32 }}>
            {TextValues.carSubHero.heading}
          </Text>
          <Text
            style={{
              fontFamily: "Uber-Light",
              fontSize: 18,
              textAlign: "center",
              maxWidth: 350,
            }}
          >
            {TextValues.carSubHero.description}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "column",
            flex: 1,
            borderRadius: 16,
            borderColor: "#F2F5F9",
            padding: 12,
            borderWidth: 1,

            marginTop: 36,
          }}
        >
          <View style={{ flexDirection: "column", flex: 1, marginRight: 16 }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Icon color="orange" name="filter-outline" type="ionicon" />
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  marginLeft: 16,
                }}
              >
                Search
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: "column",
              marginTop: 8,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                flex: 1,
                marginRight: 24,
              }}
            >
              <Icon size={18} type="ionicon" name="search-outline" />
              <TextInput
                value={searchText}
                onChangeText={(txt) => search(txt)}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="Search car ... "
              />
            </View>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: "row", alignItems: "center", margin: 20 }}>
        <Button
          disabled={tempData.length > 0 || currIndex == 0}
          icon="chevron-left"
          onPress={() => {
            if (currIndex != 0) {
              let temp = currIndex - size;
              setCurrIndex(temp);
            }
          }}
        >
          Back
        </Button>

        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          {tempData.length > 0 ? (
            <Text
              style={{
                fontFamily: "Uber-Medium",
                marginLeft: 16,
              }}
            >
              Showing search results
            </Text>
          ) : (
            <Text
              style={{
                fontFamily: "Uber-Medium",
                marginLeft: 16,
              }}
            >
              Showing {currIndex + 1} -{" "}
              {carData.length > size
                ? currIndex + size < carData.length
                  ? currIndex + size
                  : carData.length
                : carData.length}{" "}
              of {carData.length}
            </Text>
          )}
        </View>
        <Button
          disabled={
            tempData.length > 0 ||
            currIndex + size >= carData.length ||
            carData.length <= size
          }
          onPress={() => {
            let temp = currIndex + size;
            setCurrIndex(temp);
          }}
          icon="chevron-right"
        >
          Next
        </Button>
      </View>
      <View
        style={{ height: 5, width: 10 }}
        onLayout={(event) => setCarV(event.nativeEvent.layout)}
      />
      <CarList
        carData={
          tempData.length != 0
            ? tempData
            : carData.slice(currIndex, currIndex + size)
        }
      />
      <View style={{ flexDirection: "row", alignItems: "center", margin: 20 }}>
        <Button
          disabled={tempData.length > 0 || currIndex == 0}
          icon="chevron-left"
          onPress={() => {
            if (currIndex != 0) {
              let temp = currIndex - size;
              setCurrIndex(temp);
            }
          }}
        >
          Back
        </Button>

        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          {tempData.length > 0 ? (
            <Text
              style={{
                fontFamily: "Uber-Medium",
                marginLeft: 16,
              }}
            >
              Showing search results
            </Text>
          ) : (
            <Text
              style={{
                fontFamily: "Uber-Medium",
                marginLeft: 16,
              }}
            >
              Showing {currIndex + 1} -{" "}
              {carData.length > size
                ? currIndex + size < carData.length
                  ? currIndex + size
                  : carData.length
                : carData.length}{" "}
              of {carData.length}
            </Text>
          )}
        </View>
        <Button
          disabled={
            tempData.length > 0 ||
            currIndex + size >= carData.length ||
            carData.length <= size
          }
          onPress={() => {
            let temp = currIndex + size;
            setCurrIndex(temp);
          }}
          icon="chevron-right"
        >
          Next
        </Button>
      </View>
    </View>
  );
}
