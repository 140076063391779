import React, { useState, useEffect } from "react";

import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  TextInput,
} from "react-native";
import uuid from "react-native-uuid";

import * as ImagePicker from "expo-image-picker";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { doc, updateDoc, getFirestore, deleteDoc } from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import { Button } from "react-native-paper";
import { TouchableOpacity } from "react-native-web";
import { Icon } from "react-native-elements";
const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flex: 1,
    paddingHorizontal: 32,
  },
});

export default function UpdateCar({ navigation }) {
  const [image, setImage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imgStatus, setImgStatus] = useState("Update cover image");

  const [car, setCar] = useState({
    activationFee: "",
    bodyType: "",
    freeKM: "",
    fuel: "",
    kmToOwn: "",
    model: "",
    name: "",
    pricePerKm: "",
    subscription: "",
    transmission: "",
  });

  const getCar = async () => {
    let c = await AsyncStorage.getItem("@car");
    c = JSON.parse(c);
    setImage(c.url);
    setCar({ ...c, subscription: c.subscription.replace(",", "") });
  };
  useEffect(() => {
    getCar();
  }, []);

  const storage = getStorage();
  const db = getFirestore();

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.cancelled) {
      setImage(result.uri);
      setImgStatus("Select a different image");
    }
  };

  const [deleting, setDeleting] = useState(false);

  const deleteCar = async () => {
    setDeleting(true);
    await deleteDoc(doc(db, "inventory", car.id));
    setDeleting(false);
    alert("Car deleted");
    navigation.navigate("Home");
  };

  const saveCar = async () => {
    for (const value in car) {
      if (car[value] == "") {
        alert("Please fill out all fields");
        return;
      }
    }

    setUploading(true);
    const carID = car.id;

    const docRef = doc(db, "inventory", carID);
    

    if (car.url === image) {
      await updateDoc(docRef, {
        ...car,
      });
      setUploading(false);
      alert("Car updated");
    } else {
      const storageRef = ref(storage, "cars/" + carID + "/" + "cover");
      let f = await fetch(image);
      const blob = await f.blob();
      const uploadTask = uploadBytesResumable(storageRef, blob);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            await updateDoc(docRef, {
              ...car,
              url: downloadURL,
            });
            alert("Car updated");
            setUploading(false);
          });
        }
      );
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{ paddingTop: 32 }}>
        <View style={{ maxWidth: 500, width: "100%", alignSelf: "center" }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 24,
            }}
          >
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <Text style={{ fontFamily: "Uber-Bold" }}>{"<< BACK"}</Text>
            </TouchableOpacity>
            <Text
              style={{ fontFamily: "Uber-Light", marginLeft: 16, fontSize: 16 }}
            >
              Add A new car
            </Text>
          </View>

          <View
            style={{
              borderRadius: 8,
              marginVertical: 16,
              backgroundColor: "#DADADA",
              flexDirection: "column",
              paddingVertical: 8,
            }}
          >
            <TouchableOpacity onPress={() => pickImage()}>
              <View
                style={{
                  borderRadius: 8,
                  paddingHorizontal: 20,
                  marginHorizontal: 20,
                  marginTop: 24,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingVertical: 8,
                  marginBottom: 20,
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Icon style={{ marginRight: 4 }} name="add" />
                  <Text style={{ fontSize: 15 }}>{imgStatus}</Text>
                </View>
                {image != "" && (
                  <View
                    style={{
                      height: 40,
                      width: 40,
                      borderRadius: 8,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      resizeMode="contain"
                      style={{ height: 40, width: 50, borderRadius: 8 }}
                      source={{ uri: image }}
                    />
                  </View>
                )}
              </View>
            </TouchableOpacity>
            <Text style={{ marginHorizontal: 16, color: "grey" }}>
              Car name
            </Text>
            <TextInput
              value={car.name}
              onChangeText={(text) => {
                setCar((car) => ({
                  ...car,
                  name: text,
                }));
              }}
              placeholder="Car name"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                fontSize: 16,
                outlineStyle: "none",
              }}
            />
            <View
              style={{
                width: "100%",
                height: 0.5,
                backgroundColor: "silver",
                alignSelf: "center",
                marginVertical: 8,
              }}
            />
            <Text style={{ marginHorizontal: 16, color: "grey" }}>Model</Text>

            <TextInput
              value={car.model}
              onChangeText={(text) => {
                setCar((car) => ({
                  ...car,
                  model: text,
                }));
              }}
              placeholder="Model"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                fontSize: 16,
                outlineStyle: "none",
              }}
            />

            <View
              style={{
                width: "100%",
                height: 0.5,
                backgroundColor: "silver",
                alignSelf: "center",
                marginVertical: 8,
              }}
            />
            <Text style={{ marginHorizontal: 16, color: "grey" }}>
              Activation fee
            </Text>
            <TextInput
              value={car.activationFee}
              onChangeText={(text) => {
                setCar((car) => ({
                  ...car,
                  activationFee: text
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1"),
                }));
              }}
              placeholder="Activation fee"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                fontSize: 16,
                outlineStyle: "none",
              }}
            />

            <View
              style={{
                width: "100%",
                height: 0.5,
                backgroundColor: "silver",
                alignSelf: "center",
                marginVertical: 8,
              }}
            />
            <Text style={{ marginHorizontal: 16, color: "grey" }}>
              Subscription
            </Text>
            <TextInput
              value={car.subscription}
              onChangeText={(text) => {
                setCar((car) => ({
                  ...car,
                  subscription: text
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1"),
                }));
              }}
              placeholder="Monthly subscription"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                fontSize: 16,
                outlineStyle: "none",
              }}
            />
            <View
              style={{
                width: "100%",
                height: 0.5,
                backgroundColor: "silver",
                alignSelf: "center",
                marginVertical: 8,
              }}
            />
            <Text style={{ marginHorizontal: 16, color: "grey" }}>
              Cost per KM
            </Text>
            <TextInput
              value={car.pricePerKm}
              onChangeText={(text) => {
                setCar((car) => ({
                  ...car,
                  pricePerKm: text
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1"),
                }));
              }}
              placeholder="Cost per KM"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                fontSize: 16,
                outlineStyle: "none",
              }}
            />
            <View
              style={{
                width: "100%",
                height: 0.5,
                backgroundColor: "silver",
                alignSelf: "center",
                marginVertical: 8,
              }}
            />
            <Text style={{ marginHorizontal: 16, color: "grey" }}>
              Body Type
            </Text>
            <TextInput
              value={car.bodyType}
              onChangeText={(text) => {
                setCar((car) => ({
                  ...car,
                  bodyType: text,
                }));
              }}
              placeholder="Body type"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                fontSize: 16,
                outlineStyle: "none",
              }}
            />

            <View
              style={{
                width: "100%",
                height: 0.5,
                backgroundColor: "silver",
                alignSelf: "center",
                marginVertical: 8,
              }}
            />
            <Text style={{ marginHorizontal: 16, color: "grey" }}>Free KM</Text>
            <TextInput
              value={car.freeKM}
              onChangeText={(text) => {
                setCar((car) => ({
                  ...car,
                  freeKM: text
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1"),
                }));
              }}
              placeholder="Free KM"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                fontSize: 16,
                outlineStyle: "none",
              }}
            />
            <View
              style={{
                width: "100%",
                height: 0.5,
                backgroundColor: "silver",
                alignSelf: "center",
                marginVertical: 8,
              }}
            />
            <Text style={{ marginHorizontal: 16, color: "grey" }}>
              KM to Own
            </Text>
            <TextInput
              value={car.kmToOwn}
              onChangeText={(text) => {
                setCar((car) => ({
                  ...car,
                  kmToOwn: text
                    .replace(/[^0-9.]/g, "")
                    .replace(/(\..*?)\..*/g, "$1"),
                }));
              }}
              placeholder="KM to own"
              style={{
                paddingVertical: 8,
                paddingHorizontal: 16,
                fontSize: 16,
                outlineStyle: "none",
              }}
            />
            <View
              style={{
                width: "100%",
                height: 0.5,
                backgroundColor: "silver",
                alignSelf: "center",
                marginVertical: 8,
              }}
            />
            <View style={{ padding: 20 }}>
              <Text>Fuel type</Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 8,
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{ marginRight: 16 }}
                  onPress={() => {
                    setCar((car) => ({
                      ...car,
                      fuel: "Petrol",
                    }));
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: "silver",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {car.fuel == "Petrol" && <Icon size={18} name="check" />}
                    </View>
                    <Text style={{ marginLeft: 8 }}>Diesel</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setCar((car) => ({
                      ...car,
                      fuel: "Diesel",
                    }));
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: "silver",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {car.fuel == "Diesel" && <Icon size={18} name="check" />}
                    </View>
                    <Text style={{ marginLeft: 8 }}>Diesel</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ paddingHorizontal: 20, paddingBottom: 20 }}>
              <Text>Transmission</Text>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 8,
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{ marginRight: 16 }}
                  onPress={() => {
                    setCar((car) => ({
                      ...car,
                      transmission: "Automatic",
                    }));
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: "silver",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {car.transmission == "Automatic" && (
                        <Icon size={18} name="check" />
                      )}
                    </View>
                    <Text style={{ marginLeft: 8 }}>Automatic</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setCar((car) => ({
                      ...car,
                      transmission: "Manual",
                    }));
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: "silver",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {car.transmission == "Manual" && (
                        <Icon size={18} name="check" />
                      )}
                    </View>
                    <Text style={{ marginLeft: 8 }}>Manual</Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                loading={uploading}
                onPress={() => {
                  saveCar();
                }}
                style={{ marginVertical: 32, marginRight: 8 }}
                color="black"
                icon="check"
              >
                Update car
              </Button>
              <Button
                loading={deleting}
                onPress={() => {
                  deleteCar();
                }}
                style={{ marginVertical: 32, alignSelf: "center" }}
                color="red"
                icon="delete"
              >
                Delete car
              </Button>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
