import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TextInput,
  ScrollView,
  SectionList,
  Linking,
} from "react-native";
import MediaQuery from "react-responsive";
import { useFonts } from "expo-font";
import uuid from "react-native-uuid";

import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { Icon } from "react-native-elements";
import { Divider } from "react-native-paper";
import { Button } from "react-native-paper";
import {
  collection,
  getFirestore,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  list,
} from "firebase/storage";
import * as DocumentPicker from "expo-document-picker";

import * as ImagePicker from "expo-image-picker";

import CalendarPicker from "react-native-calendar-picker";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    flexDirection: "column",
    paddingHorizontal: 24,
    paddingVertical: 64,
  },
  stepBox: {
    borderRadius: 12,
    shadowColor: "blue",
    shadowOffset: { width: 2, height: 10 },
    shadowOpacity: 0.2,
    shadowRadius: 12,
    alignItems: "center",
    paddingVertical: 20,
    paddingHorizontal: 12,
    marginRight: 16,
  },
  stepHeader: {
    fontFamily: "Uber-Bold",
    marginTop: 8,
  },
  stepDescription: {
    fontFamily: "Uber-Regular",
    maxWidth: 140,
    textAlign: "center",
    marginTop: 6,
  },
});

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default function Apply({ navigation }) {
  const [car, setCar] = useState({});
  const [links, setLinks] = useState({});
  const [promo, setPromo] = useState(5);
  const getCar = async () => {
    let c = await AsyncStorage.getItem("@car");
    c = JSON.parse(c);

    setCar({ ...c, subscription: c.subscription.replace(",", "") });
    let client = await AsyncStorage.getItem("@client");
    client = JSON.parse(client);
    setProfile({
      name: client.name,
      phone: client.phone,
      id: client.id,
      email: client.email,
      income: client.income,
    });
    setLinks({
      ...client.links[0],
      ...client.links[1],
      ...client.links[2],
      ...client.links[3],
    });
    setCollectionDate(client.collectionDate);
    setLocation(client.location);

    console.log(links);
  };
  useEffect(() => {
    getCar();
  }, []);

  const [displayCalendar, setDisplayCalendar] = useState(false);

  const onDateChange = (date, type) => {
    setCollectionDate(date._d);
    setDisplayCalendar(false);
  };

  const [collectionDate, setCollectionDate] = useState("");

  const [profile, setProfile] = useState({
    name: "",
    phone: "",
    id: "",
    email: "",
    income: "",
  });

  const [documents, setDocs] = useState({
    license: null,
    bankStatement: null,
    payslip: null,
    id: null,
  });

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const viewImage = async (type) => {
    type == "id" && Linking.openURL(links.id);
    type == "payslip" && Linking.openURL(links.payslip);
    type == "license" && Linking.openURL(links.license);
  };

  const pickDocument = () => {
    Linking.openURL(links.bankStatement);
  };

  const db = getFirestore();

  const approveApplication = async () => {
    let client = await AsyncStorage.getItem("@client");
    client = JSON.parse(client);
    const docRef = doc(db, "applications", client.applicationID);
    await updateDoc(docRef, {
      approved: true,
    });
    alert("Application Approved");
    navigation.navigate("Applications");
  };

  const declineApplication = async () => {
    let client = await AsyncStorage.getItem("@client");
    client = JSON.parse(client);
    const docRef = doc(db, "applications", client.applicationID);
    await updateDoc(docRef, {
      approved: false,
    });
    alert("Application declined");
    navigation.navigate("Applications");
  };

  const [locationsVisible, setLocVisible] = useState(false);

  const Item = ({ title }) => (
    <TouchableOpacity
      onPress={() => {
        setLocation(title);
        setLocVisible(false);
      }}
    >
      <Text
        style={{ fontFamily: "Uber-Regular", marginBottom: 8, fontSize: 16 }}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );

  const [location, setLocation] = useState("");

  return (
    <View style={{ backgroundColor: "white" }}>
      <MediaQuery maxWidth={849}>
        <View
          style={{
            flexDirection: "column",
            padding: 20,
            borderRadius: 12,
            marginBottom: 32,
          }}
        >
          <View
            style={{
              padding: 20,
              borderWidth: 1,
              borderColor: "silver",
              borderRadius: 12,
            }}
          >
            <Text style={{ fontFamily: "Uber-Bold", fontSize: 18 }}>
              {car.name}
            </Text>
            <Text style={{ fontFamily: "Uber-Light", fontSize: 16 }}>
              {car.model}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Icon color="black" name="cog-outline" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Medium" }}>
                  {car.transmission}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  marginHorizontal: 24,
                }}
              >
                <Icon color="black" name="local-gas-station" type="material" />
                <Text style={{ fontFamily: "Uber-Medium" }}>{car.fuel}</Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Icon color="black" name="car-outline" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Medium" }}>
                  {car.bodyType}
                </Text>
              </View>
            </View>
            <Text
              style={{
                fontFamily: "Uber-Medium",
                fontSize: 16,
                marginTop: 32,
              }}
            >
              Drive to Own
            </Text>
            <View
              style={{
                padding: 12,
                borderRadius: 8,
                borderColor: "#E2E2E2",
                alignItems: "center",
                borderWidth: 0.8,
                marginTop: 8,
              }}
            >
              <Text style={{ fontFamily: "Uber-Regular" }}>
                <Text style={{ fontFamily: "Uber-Bold" }}>
                  {numberWithCommas(parseInt(car.freeKM))}
                </Text>{" "}
                km free included
              </Text>
              <Text style={{ fontFamily: "Uber-Regular", marginVertical: 8 }}>
                <Text style={{ fontFamily: "Uber-Bold" }}>
                  R{car.pricePerKm}
                </Text>{" "}
                per extra KM
              </Text>
              <Text style={{ fontFamily: "Uber-Regular", textAlign: "center" }}>
                Own the car after driving
                <Text style={{ fontFamily: "Uber-Bold" }}>
                  {" "}
                  {numberWithCommas(parseInt(car.kmToOwn))}
                </Text>
                KM
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "Uber-Medium",
                fontSize: 16,
                marginTop: 24,
              }}
            >
              What's included?
            </Text>
            <Text
              style={{ fontFamily: "Uber-Light", fontSize: 16, marginTop: 8 }}
            >
              - Service and Maintenance{"\n"}- Insurance{"\n"}- Free 1,000KM
              {"\n"}- 24/7 Road Assistance
            </Text>
            <Divider style={{ marginVertical: 16 }} />
            <View style={{ flexDirection: "row" }}>
              <View style={{ marginRight: 16 }}>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  Monthly subscription
                </Text>
                <Text style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}>
                  Once-off activation fee
                </Text>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  Promotional discount
                </Text>
                <Text style={{ fontFamily: "Uber-Bold", marginVertical: 7 }}>
                  Total
                </Text>
              </View>
              <View>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  R{numberWithCommas(parseFloat(car.subscription).toFixed(0))}
                </Text>
                <Text style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}>
                  R{numberWithCommas(parseFloat(car.activationFee).toFixed(0))}
                </Text>

                <Text style={{ fontFamily: "Uber-Regular" }}>
                  R
                  {numberWithCommas(
                    ((parseFloat(car.activationFee) * promo) / 100).toFixed(0)
                  )}
                </Text>

                <Text style={{ fontFamily: "Uber-Bold", marginVertical: 7 }}>
                  R
                  {numberWithCommas(
                    (
                      parseFloat(car.activationFee) +
                      parseFloat(car.subscription) -
                      (parseFloat(car.activationFee) * promo) / 100
                    ).toFixed(0)
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              shadowColor: "black",
              shadowOffset: { width: 2, height: 10 },
              shadowOpacity: 0.2,
              shadowRadius: 12,
              padding: 20,
              borderRadius: 12,
              marginTop: 24,
            }}
          >
            <Text
              style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
            >
              Collection details
            </Text>

            <View
              style={{
                flexDirection: "column",
                marginTop: 16,
                marginVertical: 18,
              }}
            >
              <View style={{ marginBottom: 8 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: 16,
                    borderRadius: 8,
                    backgroundColor: "#191819",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    numberOfLines={1}
                    style={{
                      fontFamily: "Uber-Regular",
                      marginRight: 8,
                      color: "white",
                      maxWidth: 200,
                    }}
                  >
                    {collectionDate == "" ? "Collection date" : collectionDate}
                  </Text>
                  <Icon
                    color="white"
                    size={18}
                    name="calendar-outline"
                    type="ionicon"
                  />
                </View>
              </View>

              <View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: 16,
                    borderRadius: 8,
                    backgroundColor: "#191819",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    numberOfLines={1}
                    style={{
                      fontFamily: "Uber-Regular",
                      marginRight: 8,
                      color: "white",
                      maxWidth: 200,
                    }}
                  >
                    {location == "" ? "Collection location" : location}
                  </Text>
                  <Icon
                    color="white"
                    size={18}
                    name="location-outline"
                    type="ionicon"
                  />
                </View>
              </View>
            </View>

            <Text
              style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
            >
              Personal Details
            </Text>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                disabled
                onChangeText={(text) => {
                  setProfile({ ...profile, name: text });
                }}
                value={profile.name}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="First and Last name "
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                disabled
                onChangeText={(text) => {
                  setProfile({ ...profile, phone: text });
                }}
                value={profile.phone}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="Contact number"
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                disabled
                onChangeText={(text) => {
                  setProfile({ ...profile, id: text });
                }}
                value={profile.id}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="ID (SA) / Passport Number "
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                disabled
                onChangeText={(text) => {
                  setProfile({ ...profile, email: text });
                }}
                value={profile.email}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="Email address "
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                disabled
                onChangeText={(text) => {
                  setProfile({ ...profile, income: text });
                }}
                value={profile.income}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="Gross income"
              />
            </View>

            <Text
              style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
            >
              Documents
            </Text>
            {links.id != null && links.id != "" ? (
              <TouchableOpacity onPress={() => viewImage("id")}>
                <View
                  style={{
                    borderRadius: 12,
                    paddingHorizontal: 20,

                    marginVertical: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    backgroundColor: "#F9F3F8",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon style={{ marginRight: 7 }} name="chevron-right" />

                    <Text style={{ fontSize: 15 }}>View ID</Text>
                  </View>
                  <Image
                    style={{ height: 30, width: 30, borderRadius: 8 }}
                    source={{ uri: links.id }}
                  />
                </View>
              </TouchableOpacity>
            ) : (
              <View style={{ marginVertical: 8 }}>
                <Text>- ID Missing</Text>
              </View>
            )}

            {links.bankStatement != null ? (
              <TouchableOpacity onPress={() => pickDocument()}>
                <View
                  style={{
                    borderRadius: 12,
                    paddingHorizontal: 20,

                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    backgroundColor: "#F9F3F8",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon
                      style={{ marginRight: 7 }}
                      name="picture-as-pdf"
                      type="material"
                    />

                    <Text style={{ fontSize: 15 }}>Bank statement</Text>
                  </View>
                  {links.bankStatement != null && (
                    <Icon color="green" size={18} name="check" />
                  )}
                </View>
              </TouchableOpacity>
            ) : (
              <View style={{ marginBottom: 8 }}>
                <Text>- Bank statement missing</Text>
              </View>
            )}
            {links.payslip != null ? (
              <TouchableOpacity onPress={() => viewImage("payslip")}>
                <View
                  style={{
                    borderRadius: 12,
                    paddingHorizontal: 20,

                    marginVertical: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    backgroundColor: "#F9F3F8",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon style={{ marginRight: 7 }} name="chevron-right" />

                    <Text style={{ fontSize: 15 }}>Latest payslip</Text>
                  </View>
                  {links.payslip != null && (
                    <Image
                      style={{ height: 30, width: 30, borderRadius: 8 }}
                      source={{ uri: links.payslip }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            ) : (
              <View style={{ marginBottom: 8 }}>
                <Text>- Payslip missing</Text>
              </View>
            )}
            {links.license != null ? (
              <TouchableOpacity onPress={() => viewImage("license")}>
                <View
                  style={{
                    borderRadius: 12,
                    paddingHorizontal: 20,

                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    backgroundColor: "#F9F3F8",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon style={{ marginRight: 7 }} name="chevron-right" />

                    <Text style={{ fontSize: 15 }}>Driver's license</Text>
                  </View>
                  {links.license != null && (
                    <Image
                      style={{ height: 30, width: 30, borderRadius: 8 }}
                      source={{ uri: links.license }}
                    />
                  )}
                </View>
              </TouchableOpacity>
            ) : (
              <View style={{ marginBottom: 24 }}>
                <Text>- License missing</Text>
              </View>
            )}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 24,
              }}
            >
              <Button
                onPress={() => navigation.goBack()}
                icon="chevron-left"
                contentStyle={{ height: 45 }}
                mode="contained"
                color="black"
                theme={{ roundness: 8 }}
              >
                Back
              </Button>
              <Button
                contentStyle={{ height: 45 }}
                theme={{ roundness: 8 }}
                icon="cancel"
                mode="contained"
                onPress={() => declineApplication()}
                color="red"
                style={{}}
              >
                Decline
              </Button>
              <Button
                contentStyle={{ height: 45 }}
                theme={{ roundness: 8 }}
                icon="check"
                mode="contained"
                onPress={() => approveApplication()}
                color="orange"
                style={{ marginRight: 30 }}
              >
                Approve
              </Button>
            </View>
          </View>
        </View>
      </MediaQuery>
      <MediaQuery minWidth={850}>
        <ScrollView contentContainerStyle={styles.container}>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              padding: 20,
              borderWidth: 1,
              borderColor: "silver",
              backgroundColor: "white",
              borderRadius: 12,
            }}
          >
            <View
              style={{
                shadowColor: "black",
                shadowOffset: { width: 2, height: 10 },
                shadowOpacity: 0.2,
                shadowRadius: 12,
                padding: 20,
                borderRadius: 12,
                marginRight: 16,
              }}
            >
              <Text
                style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
              >
                Collection details
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 16,
                  marginVertical: 18,
                  alignItems: "center",
                }}
              >
                <View style={{ marginRight: 16 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingHorizontal: 8,
                      borderRadius: 8,
                      backgroundColor: "#191819",
                      paddingVertical: 7,
                    }}
                  >
                    <Text
                      numberOfLines={1}
                      style={{
                        fontFamily: "Uber-Regular",
                        marginRight: 8,
                        color: "white",
                        maxWidth: 200,
                      }}
                    >
                      {collectionDate == ""
                        ? "Collection date"
                        : collectionDate}
                    </Text>
                    <Icon
                      color="white"
                      size={18}
                      name="calendar-outline"
                      type="ionicon"
                    />
                  </View>
                </View>
                <View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingHorizontal: 8,
                      borderRadius: 8,
                      backgroundColor: "#191819",
                      paddingVertical: 7,
                    }}
                  >
                    <Text
                      numberOfLines={1}
                      style={{
                        fontFamily: "Uber-Regular",
                        marginRight: 8,
                        color: "white",
                        maxWidth: 200,
                      }}
                    >
                      {location == "" ? "Collection location" : location}
                    </Text>
                    <Icon
                      color="white"
                      size={18}
                      name="location-outline"
                      type="ionicon"
                    />
                  </View>
                </View>
              </View>

              {displayCalendar && (
                <CalendarPicker
                  minDate={new Date().addDays(2).toDateString()}
                  allowBackwardRangeSelect={false}
                  headerWrapperStyle={{ width: 300 }}
                  scaleFactor={605}
                  onDateChange={onDateChange}
                />
              )}

              <Text
                style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
              >
                Personal Details
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#F2F5F9",
                  borderRadius: 8,
                  paddingLeft: 12,
                  alignItems: "center",
                  borderWidth: 1,
                  flex: 1,
                  marginRight: 24,
                  marginTop: 8,
                }}
              >
                <TextInput
                  disabled
                  onChangeText={(text) => {
                    setProfile({ ...profile, name: text });
                  }}
                  value={profile.name}
                  style={{
                    paddingVertical: 12,
                    flex: 1,
                    paddingHorizontal: 8,
                    outlineStyle: "none",
                  }}
                  placeholder="First and Last name "
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#F2F5F9",
                  borderRadius: 8,
                  paddingLeft: 12,
                  alignItems: "center",
                  borderWidth: 1,
                  flex: 1,
                  marginRight: 24,
                  marginTop: 8,
                }}
              >
                <TextInput
                  disabled
                  onChangeText={(text) => {
                    setProfile({ ...profile, phone: text });
                  }}
                  value={profile.phone}
                  style={{
                    paddingVertical: 12,
                    flex: 1,
                    paddingHorizontal: 8,
                    outlineStyle: "none",
                  }}
                  placeholder="Contact number"
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#F2F5F9",
                  borderRadius: 8,
                  paddingLeft: 12,
                  alignItems: "center",
                  borderWidth: 1,
                  flex: 1,
                  marginRight: 24,
                  marginTop: 8,
                }}
              >
                <TextInput
                  disabled
                  onChangeText={(text) => {
                    setProfile({ ...profile, id: text });
                  }}
                  value={profile.id}
                  style={{
                    paddingVertical: 12,
                    flex: 1,
                    paddingHorizontal: 8,
                    outlineStyle: "none",
                  }}
                  placeholder="ID (SA) / Passport Number "
                />
              </View>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    flexDirection: "row",
                    borderColor: "#F2F5F9",
                    borderRadius: 8,
                    paddingLeft: 12,
                    alignItems: "center",
                    borderWidth: 1,
                    flex: 1,
                    marginRight: 24,
                    marginTop: 8,
                  }}
                >
                  <TextInput
                    disabled
                    onChangeText={(text) => {
                      setProfile({ ...profile, email: text });
                    }}
                    value={profile.email}
                    style={{
                      paddingVertical: 12,
                      flex: 1,
                      paddingHorizontal: 8,
                      outlineStyle: "none",
                    }}
                    placeholder="Email address "
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    borderColor: "#F2F5F9",
                    borderRadius: 8,
                    paddingLeft: 12,
                    alignItems: "center",
                    borderWidth: 1,
                    flex: 1,
                    marginRight: 24,
                    marginTop: 8,
                  }}
                >
                  <TextInput
                    disabled
                    onChangeText={(text) => {
                      setProfile({ ...profile, income: text });
                    }}
                    value={profile.income}
                    style={{
                      paddingVertical: 12,
                      flex: 1,
                      paddingHorizontal: 8,
                      outlineStyle: "none",
                    }}
                    placeholder="Gross income"
                  />
                </View>
              </View>

              <Text
                style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
              >
                Documents
              </Text>
              {links.id != null && links.id != "" ? (
                <TouchableOpacity onPress={() => viewImage("id")}>
                  <View
                    style={{
                      borderRadius: 12,
                      paddingHorizontal: 20,

                      marginVertical: 8,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingVertical: 12,
                      backgroundColor: "#F9F3F8",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Icon style={{ marginRight: 7 }} name="chevron-right" />

                      <Text style={{ fontSize: 15 }}>View ID</Text>
                    </View>
                    <Image
                      style={{ height: 30, width: 30, borderRadius: 8 }}
                      source={{ uri: links.id }}
                    />
                  </View>
                </TouchableOpacity>
              ) : (
                <View style={{ marginVertical: 8 }}>
                  <Text>- ID Missing</Text>
                </View>
              )}

              {links.bankStatement != null ? (
                <TouchableOpacity onPress={() => pickDocument()}>
                  <View
                    style={{
                      borderRadius: 12,
                      paddingHorizontal: 20,

                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingVertical: 12,
                      backgroundColor: "#F9F3F8",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Icon
                        style={{ marginRight: 7 }}
                        name="picture-as-pdf"
                        type="material"
                      />

                      <Text style={{ fontSize: 15 }}>Bank statement</Text>
                    </View>
                    {links.bankStatement != null && (
                      <Icon color="green" size={18} name="check" />
                    )}
                  </View>
                </TouchableOpacity>
              ) : (
                <View style={{ marginBottom: 8 }}>
                  <Text>- Bank statement missing</Text>
                </View>
              )}
              {links.payslip != null ? (
                <TouchableOpacity onPress={() => viewImage("payslip")}>
                  <View
                    style={{
                      borderRadius: 12,
                      paddingHorizontal: 20,

                      marginVertical: 8,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingVertical: 12,
                      backgroundColor: "#F9F3F8",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Icon style={{ marginRight: 7 }} name="chevron-right" />

                      <Text style={{ fontSize: 15 }}>Latest payslip</Text>
                    </View>
                    {links.payslip != null && (
                      <Image
                        style={{ height: 30, width: 30, borderRadius: 8 }}
                        source={{ uri: links.payslip }}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              ) : (
                <View style={{ marginBottom: 8 }}>
                  <Text>- Payslip missing</Text>
                </View>
              )}
              {links.license != null ? (
                <TouchableOpacity onPress={() => viewImage("license")}>
                  <View
                    style={{
                      borderRadius: 12,
                      paddingHorizontal: 20,

                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingVertical: 12,
                      backgroundColor: "#F9F3F8",
                    }}
                  >
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <Icon style={{ marginRight: 7 }} name="chevron-right" />

                      <Text style={{ fontSize: 15 }}>Driver's license</Text>
                    </View>
                    {links.license != null && (
                      <Image
                        style={{ height: 30, width: 30, borderRadius: 8 }}
                        source={{ uri: links.license }}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              ) : (
                <View style={{ marginBottom: 24 }}>
                  <Text>- License missing</Text>
                </View>
              )}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 24,
                }}
              >
                <Button
                  onPress={() => navigation.goBack()}
                  icon="chevron-left"
                  contentStyle={{ height: 45 }}
                  mode="contained"
                  color="black"
                  theme={{ roundness: 8 }}
                >
                  Back
                </Button>
                <Button
                  contentStyle={{ height: 45 }}
                  theme={{ roundness: 8 }}
                  icon="cancel"
                  mode="contained"
                  onPress={() => declineApplication()}
                  color="red"
                  style={{}}
                >
                  Decline
                </Button>
                <Button
                  contentStyle={{ height: 45 }}
                  theme={{ roundness: 8 }}
                  icon="check"
                  mode="contained"
                  onPress={() => approveApplication()}
                  color="orange"
                  style={{ marginRight: 30 }}
                >
                  Approve
                </Button>
              </View>
            </View>

            <View
              style={{
                padding: 20,
                borderWidth: 1,
                borderColor: "#E2E2E2",
                backgroundColor: "white",
                borderRadius: 12,
                height: "100%",
              }}
            >
              <Text style={{ fontFamily: "Uber-Bold", fontSize: 18 }}>
                {car.name}
              </Text>
              <Text style={{ fontFamily: "Uber-Light", fontSize: 16 }}>
                {car.model}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Icon color="black" name="cog-outline" type="ionicon" />
                  <Text style={{ fontFamily: "Uber-Medium" }}>
                    {car.transmission}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    marginHorizontal: 24,
                  }}
                >
                  <Icon
                    color="black"
                    name="local-gas-station"
                    type="material"
                  />
                  <Text style={{ fontFamily: "Uber-Medium" }}>{car.fuel}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Icon color="black" name="car-outline" type="ionicon" />
                  <Text style={{ fontFamily: "Uber-Medium" }}>
                    {car.bodyType}
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  fontSize: 16,
                  marginTop: 32,
                }}
              >
                Drive to Own
              </Text>
              <View
                style={{
                  padding: 12,
                  borderRadius: 8,
                  borderColor: "#E2E2E2",
                  alignItems: "center",
                  borderWidth: 0.8,
                  marginTop: 8,
                }}
              >
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    {numberWithCommas(parseInt(car.freeKM))}
                  </Text>{" "}
                  km free included
                </Text>
                <Text style={{ fontFamily: "Uber-Regular", marginVertical: 8 }}>
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    R{car.pricePerKm}
                  </Text>{" "}
                  per extra KM
                </Text>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  Own the car after driving
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    {" "}
                    {numberWithCommas(parseInt(car.kmToOwn))}
                  </Text>
                  KM
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  fontSize: 16,
                  marginTop: 24,
                }}
              >
                What's included?
              </Text>
              <Text
                style={{ fontFamily: "Uber-Light", fontSize: 16, marginTop: 8 }}
              >
                - Service and Maintenance{"\n"}- Insurance{"\n"}- Free 1,000KM
                {"\n"}- 24/7 Road Assistance
              </Text>
              <Divider style={{ marginVertical: 16 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ marginRight: 16 }}>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    Monthly subscription
                  </Text>
                  <Text
                    style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}
                  >
                    Once-off activation fee
                  </Text>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    Promotional discount
                  </Text>
                  <Text style={{ fontFamily: "Uber-Bold", marginVertical: 7 }}>
                    Total
                  </Text>
                </View>
                <View>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    R{numberWithCommas(parseFloat(car.subscription).toFixed(0))}
                  </Text>
                  <Text
                    style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}
                  >
                    R
                    {numberWithCommas(parseFloat(car.activationFee).toFixed(0))}
                  </Text>

                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    R
                    {numberWithCommas(
                      ((parseFloat(car.activationFee) * promo) / 100).toFixed(0)
                    )}
                  </Text>

                  <Text style={{ fontFamily: "Uber-Bold", marginVertical: 7 }}>
                    R
                    {numberWithCommas(
                      (
                        parseFloat(car.activationFee) +
                        parseFloat(car.subscription) -
                        (parseFloat(car.activationFee) * promo) / 100
                      ).toFixed(0)
                    )}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                  marginTop: 64,
                }}
              >
                <Icon name="checkmark-circle" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Light", marginLeft: 8 }}>
                  No credit checks
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <Icon name="checkmark-circle" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Light", marginLeft: 4 }}>
                  Inclusive
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <Icon name="checkmark-circle" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Light", marginLeft: 4 }}>
                  Prepaid car
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </MediaQuery>
    </View>
  );
}
