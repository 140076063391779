import React, { useState, useEffect } from "react";

import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  Image,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-paper";
import { Icon } from "react-native-elements";
import {} from "react-native-web";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
});

export default function Policy({ navigation, route }) {
  return (
    <View style={styles.container}>
      <iframe
        width="100%"
        height="100%"
        src="https://redzoneafrica.com/docs/policies.pdf"
      ></iframe>
    </View>
  );
}
