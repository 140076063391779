import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Icon } from "react-native-elements";
import { Button } from "react-native-paper";
import { TextValues } from "../../constants/Strings";
import { useState } from "react";

const styles = StyleSheet.create({
  heading: {
    fontFamily: "Uber-Bold",
    fontSize: 24,
    alignSelf: "center",
    marginBottom: 32,
  },
  boxHeading: {
    fontFamily: "Uber-Bold",
    fontSize: 18,
    textAlign: "center",
    maxWidth: 300,
    alignSelf: "center",
  },
  boxDescription: {
    fontFamily: "Uber-Light",
    fontSize: 20,
    marginTop: 8,
    textAlign: "center",
    maxWidth: 300,
  },
  box: {
    flexDirection: "column",
    marginBottom: 32,
    shadowColor: "black",
    shadowOffset: { width: 2, height: 10 },
    shadowOpacity: 0.1,
    shadowRadius: 24,
    paddingTop: 16,
    padding: 20,
    borderRadius: 8,
    backgroundColor: "orange",
    marginRight: 16,
    marginLeft: 20,
  },
  iconContainer: {
    backgroundColor: "white",
    height: 50,
    width: 50,
    justifyContent: "center",
    borderRadius: 25,
    alignItems: "center",
    marginLeft: 16,
    marginBottom: 16,
  },
});

export default function HowItWorks() {
  const [oneVisible, setOneVisible] = useState(true);
  const [twoVisible, setTwoVisible] = useState(false);
  const [threeVisible, setThreeVisible] = useState(false);
  const [fourVisible, setFourVisible] = useState(false);

  const changeVisible = (card) => {
    switch (card) {
      case "one":
        setOneVisible(true);
        setTwoVisible(false);
        setThreeVisible(false);
        setFourVisible(false);
        break;

      case "two":
        setOneVisible(false);
        setTwoVisible(true);
        setThreeVisible(false);
        setFourVisible(false);
        break;
      case "three":
        setOneVisible(false);
        setTwoVisible(false);
        setThreeVisible(true);
        setFourVisible(false);
        break;
      case "four":
        setOneVisible(false);
        setTwoVisible(false);
        setThreeVisible(false);
        setFourVisible(true);
        break;
    }
  };
  return (
    <View style={{ flexDirection: "column" }}>
      <View
        style={{
          flexDirection: "column",
          paddingTop: 54,
        }}
      >
        <ScrollView horizontal contentContainerStyle={{}} style={{}}>
          <Text style={[styles.heading, { marginHorizontal: 32 }]}>
            How does{"\n"}it work?
          </Text>
          <View style={styles.box}>
            <View style={{ flexDirection: "column", alignItems: "center" }}>
              <View style={styles.iconContainer}>
                <Icon size={28} name="document-text-outline" type="ionicon" />
              </View>
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.boxHeading}>
                  {TextValues.howItWorks[0].title}
                </Text>
                <Text style={styles.boxDescription}>
                  {TextValues.howItWorks[0].description}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.box}>
            <View style={{ flexDirection: "column", alignItems: "center" }}>
              <View style={styles.iconContainer}>
                <Icon size={28} name="car-outline" type="ionicon" />
              </View>
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.boxHeading}>
                  {TextValues.howItWorks[1].title}
                </Text>
                <Text style={styles.boxDescription}>
                  {TextValues.howItWorks[1].description}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.box}>
            <View style={{ flexDirection: "column", alignItems: "center" }}>
              <View style={styles.iconContainer}>
                <Icon size={28} name="cloud-download-outline" type="ionicon" />
              </View>
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.boxHeading}>
                  {TextValues.howItWorks[2].title}
                </Text>
                <Text style={styles.boxDescription}>
                  {TextValues.howItWorks[2].description}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.box}>
            <View style={{ flexDirection: "column", alignItems: "center" }}>
              <View style={styles.iconContainer}>
                <Icon
                  size={28}
                  name="shield-checkmark-outline"
                  type="ionicon"
                />
              </View>
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.boxHeading}>
                  {TextValues.howItWorks[3].title}
                </Text>
                <Text style={styles.boxDescription}>
                  {TextValues.howItWorks[3].description}
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>

        {false && (
          <View
            style={{ flexDirection: "row", alignSelf: "center", padding: 32 }}
          >
            <TouchableOpacity
              onPress={() => changeVisible("one")}
              style={{
                marginHorizontal: 4,
              }}
            >
              <View
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 10,
                  backgroundColor: "black",
                  borderColor: "black",
                  marginHorizontal: 8,
                  borderWidth: 1,
                  backgroundColor: oneVisible ? "black" : "#F4F4F4",
                }}
              ></View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => changeVisible("two")}
              style={{
                marginHorizontal: 4,
              }}
            >
              <View
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 10,
                  borderColor: "black",
                  marginHorizontal: 8,
                  borderWidth: 1,
                  backgroundColor: twoVisible ? "black" : "#F4F4F4",
                }}
              ></View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => changeVisible("three")}
              style={{
                marginHorizontal: 4,
              }}
            >
              <View
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 10,
                  borderColor: "black",
                  marginHorizontal: 8,
                  borderWidth: 1,
                  backgroundColor: threeVisible ? "black" : "#F4F4F4",
                }}
              ></View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => changeVisible("four")}
              style={{
                marginHorizontal: 4,
              }}
            >
              <View
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 10,
                  borderColor: "black",
                  marginHorizontal: 8,
                  borderWidth: 1,
                  backgroundColor: fourVisible ? "black" : "#F4F4F4",
                }}
              ></View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
}
