import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Icon } from "react-native-elements";

import { TextValues } from "../../constants/Strings";
const styles = StyleSheet.create({
  logo: { height: 50, width: 120 },
  logoText: {
    fontFamily: "Uber-Bold",
    fontSize: 18,
    marginLeft: 12,
  },
  menuText: {
    fontFamily: "Uber-Bold",
    fontSize: 16,
    marginLeft: 8,
  },
  menuTextContainer: {
    marginLeft: 24,
    flexDirection: "row",
    alignItems: "center",
  },
  downloadButtonContainer: {
    backgroundColor: "#ffddb0",
    borderRadius: 24,
    paddingHorizontal: 16,
    marginLeft: 16,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
  },
  downloadButtonText: {
    marginLeft: 8,
    fontSize: 16,
    color: "black",
    fontFamily: "Uber-Medium",
  },
});

export default function Header({ scrollV, carV, faqV, len }) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 32,
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Image
          resizeMode="contain"
          style={styles.logo}
          source={require("../../assets/images/logo-main.png")}
        />
      </View>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {false && (
          <TouchableOpacity style={styles.menuTextContainer}>
            <Icon name="information-circle" type="ionicon" size={18} />
            <Text style={styles.menuText}>About Us</Text>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={() => {
            scrollV.current.scrollTo({
              x: 0,
              y: carV.y + 500,
              animated: true,
            });
          }}
          style={styles.menuTextContainer}
        >
          <Icon name="car-sport" type="ionicon" size={18} />
          <Text style={styles.menuText}>Cars</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            let added = len > 0 ? 2000 : 0;
            scrollV.current.scrollTo({
              x: 0,
              y: faqV.y + added,
              animated: true,
            });
          }}
          style={styles.menuTextContainer}
        >
          <Icon name="help-circle-outline" type="ionicon" size={20} />
          <Text style={styles.menuText}>Help</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(TextValues.links.terms);
          }}
          style={styles.menuTextContainer}
        >
          <Icon name="briefcase" type="ionicon" size={18} />
          <Text style={styles.menuText}>Terms of Service</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(TextValues.links.webapp);
          }}
          style={styles.downloadButtonContainer}
        >
          <Icon color="black" size={18} type="ionicon" name="log-in" />
          <Text style={styles.downloadButtonText}>Sign In</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
