import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Icon } from "react-native-elements";
import { Button } from "react-native-paper";
import { TextValues } from "../../constants/Strings";

const styles = StyleSheet.create({
  img1: {},
  img2: {},
  heading: {
    fontSize: 24,
    fontFamily: "Uber-Bold",
    marginVertical: 8,
    textAlign: "center",
  },
  description: {
    maxWidth: 300,
    fontSize: 20,
    fontFamily: "Uber-Light",
    marginBottom: 24,
    textAlign: "center",
  },
  subtitle: {
    fontFamily: "Uber-Light",
  },
  btn: {
    backgroundColor: "black",
    borderRadius: 24,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    alignSelf: "flex-start",
  },
  downloadButtonText: {
    marginLeft: 8,
    fontSize: 16,
    fontFamily: "Uber-Medium",
    color: "#78FFBD",
  },
});

export default function AppBox() {
  return (
    <View
      style={{
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        marginBottom: 36,
        paddingVertical: 36,
        marginTop: 32,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Image
          resizeMode="contain"
          style={{
            width: 250,
            height: 350,
          }}
          source={require("../../assets/app/home.webp")}
        />
        <Image
          resizeMode="contain"
          style={{
            width: 150,
            height: 250,
            marginLeft: -100,
            marginTop: 110,
          }}
          source={require("../../assets/app/trips.webp")}
        />
      </View>
      <View style={{ flexDirection: "column" }}>
        <Text style={styles.heading}>Drive to Own App</Text>
        <Text style={styles.description}>{TextValues.appDescription}</Text>

        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <View style={{ flexDirection: "row", marginTop: 16 }}>
            <Icon
              size={18}
              type="ionicon"
              color="#005E63"
              name="logo-android"
            />
            <Text
              style={{ marginLeft: 8, fontWeight: "bold", color: "#005E63" }}
            >
              Android
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 12,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(TextValues.appLinks.playstore);
              }}
              style={{
                padding: 2,
                marginRight: 16,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  marginRight: 5,
                }}
              >
                Get from Playstore
              </Text>
              <Icon name="chevron-right" size={14} />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                Linking.openURL(TextValues.appLinks.direct);
              }}
              style={{
                padding: 2,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  marginRight: 5,
                }}
              >
                Direct download
              </Text>
              <Icon name="chevron-right" size={14} />
            </TouchableOpacity>
          </View>
          <View
            style={{
              flexDirection: "row",
              marginTop: 24,
              alignItems: "center",
            }}
          >
            <Icon size={18} type="ionicon" name="logo-apple" />
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(TextValues.appLinks.appStore);
              }}
              style={{
                padding: 2,
                marginLeft: 8,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  marginRight: 5,
                }}
              >
                Get from App Store{" "}
              </Text>

              <Icon name="chevron-right" size={14} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}
