export const TextValues = {
  hero: "Drive based on your income and budget",
  heroDescription:
    "Own the car after driving 100,000km. Change your mind about ownership? You can return or upgrade at anytime.",
  howItWorks: [
    {
      title: "Apply for a car",
      description:
        "Like what you see? Apply instantly online without phone calls or tedious paperwork.",
    },
    {
      title: "Collect your car",
      description:
        "Go to your nearest Europcar branch with your Driver’s license and drive off in your car.",
    },
    {
      title: "Download the app",
      description:
        "Monitor your trips and top-up with more kilometers on our Mobile App",
    },
    {
      title: "Full service, maintenance and insurance:",
      description:
        "All cars are fully covered for service, maintenance and insurance so you will have a worry-free drive.",
    },
  ],
  links: {
    whatsapp: "https://wa.me/27658932664",
    terms: "https://drivetoown.co.za/Terms",
    policy: "https://drivetoown.co.za/Policy",
    webapp: "https://app.drivetoown.co.za",
  },
  carCTA: {
    heading: "Just drive to own it!",
    description: "Flexible and affordable prepaid car",
  },
  carSubHero: {
    heading: "Hussle-free car ownership",
    description:
      "No maintenance and insurance costs. No long term commitments. Return, Upgrade or Own based on your convenience.",
  },
  faqs: [
    {
      question:
        "Do my kilometers roll over to the next month if I drive less than 1000km?",
      answer: "No, the kilometers expires after a 30 day cycle",
    },
    {
      question: "I am blacklisted/under debt review do i qualify?",
      answer:
        "Your application is assessed based on your Affordability not your credit record.",
    },
    {
      question: "How long is the Drive to Own agreement?",
      answer:
        "The Drive to Own agreement is for an indefinite period; however, you have the option to buy out the vehicle from us after you have driven 100 000km.",
    },
    {
      question: "Can I use the car for ride-hailing?",
      answer:
        "No, Drive to Own cars cannot be used for ride-hailing because our insurance policy does not cover cars used for this purpose.",
    },
    {
      question: "Can I use the car for ride-hailing?",
      answer:
        "No, Drive to Own cars cannot be used for ride-hailing because our insurance policy does not cover cars used for this purpose.",
    },
    {
      question: "Can I use the car for ride-hailing?",
      answer:
        "No, Drive to Own cars cannot be used for ride-hailing because our insurance policy does not cover cars used for this purpose.",
    },
    {
      question: "I am self-employed. Can you help?",
      answer:
        "Yes! Apply online on www.drivetoown.co.za to see if you qualify.",
    },
    {
      question: "How do I apply to see if I qualify?",
      answer:
        "You are welcome to submit an application through our online web approval form. If your pre-approval is successful, final approval will be subject to submitting all relevant documents.",
    },
    {
      question: "What type of vehicles do you offer?",
      answer:
        "We have variety of cars which are almost brand new. Click the browse cars button to check our fleet. The fleet is updated regularly.",
    },
    {
      question: "What type of vehicles do you offer?",
      answer:
        "We have variety of cars which are almost brand new. Click the browse cars button to check our fleet. The fleet is updated regularly.",
    },
    {
      question: "Can I upgrade/swap the vehicle after a while?",
      answer:
        "You can swap/upgrade in the vehicle at any time, subject to affordability review. You can downgrade too!",
    },
    {
      question: "Who is responsible for maintenance, servicing and licensing?",
      answer:
        "All maintenance and servicing costs are Paid and managed by Drive to Own; this includes your annual licensing of the vehicle.",
    },
    {
      question: "Does the vehicle have insurance or a motor warranty?",
      answer:
        "Yes, all our vehicles come with fully comprehensive insurance, covering the vehicle for accident, theft or fire, which will assist with a mechanical breakdown",
    },
  ],
  appDescription:
    "Easily monitor your trips, top-up your account and manage your subscription, all in one place.",
  appLinks: {
    direct:
      "https://firebasestorage.googleapis.com/v0/b/spring-ship-233716.appspot.com/o/Drive%20to%20Own.apk?alt=media&token=313f82d2-c3c5-4c1f-a97b-9769260800fe",
    appStore: "https://apps.apple.com/app/id6443989151",
    playstore:
      "https://play.google.com/store/apps/details?id=com.drivetoown.app",
  },
};
