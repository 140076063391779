import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Icon } from "react-native-elements";
import { Button } from "react-native-paper";
import { TextValues } from "../../constants/Strings";

const styles = StyleSheet.create({
  img1: {},
  img2: {},
  heading: {
    fontSize: 32,
    fontFamily: "Uber-Bold",
    marginVertical: 8,
  },
  description: {
    maxWidth: 350,
    fontSize: 24,
    fontFamily: "Uber-Light",
    marginBottom: 24,
  },
  subtitle: {
    fontFamily: "Uber-Light",
  },
  btn: {
    backgroundColor: "black",
    borderRadius: 24,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    alignSelf: "flex-start",
  },
  downloadButtonText: {
    marginLeft: 8,
    fontSize: 16,
    fontFamily: "Uber-Medium",
    color: "#78FFBD",
  },
});

export default function AppBox() {
  return (
    <View
      style={{
        flexDirection: "column",
        marginHorizontal: 72,
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        backgroundColor: "#F5F5F5",
        paddingHorizontal: 72,
        borderRadius: 12,
        marginBottom: 36,
        paddingVertical: 36,
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <Image
          resizeMode="contain"
          style={{
            width: 300,
            height: 450,
            transform: [{ rotateX: "-20deg" }, { rotateZ: "-20deg" }],
          }}
          source={require("../../assets/app/home.webp")}
        />
        <Image
          resizeMode="contain"
          style={{
            width: 300,
            height: 450,
            transform: [{ rotateX: "25deg" }, { rotateZ: "25deg" }],
            marginLeft: -100,
          }}
          source={require("../../assets/app/trips.webp")}
        />
      </View>
      <View style={{ flexDirection: "column" }}>
        <Text style={styles.subtitle}>CONVENIENT INTERACTION</Text>
        <Text style={styles.heading}>Drive to Own App</Text>
        <Text style={styles.description}>{TextValues.appDescription}</Text>
        <Text style={{ fontSize: 18, fontWeight: "bold" }}>
          Download options
        </Text>

        <View style={{ flexDirection: "row" }}>
          <View>
            <View style={{ flexDirection: "row", marginTop: 16 }}>
              <Icon size={18} type="ionicon" name="logo-android" />
              <Text style={{ marginLeft: 8 }}>Android</Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(TextValues.appLinks.playstore);
              }}
              style={{
                padding: 2,
              }}
            >
              <Text
                style={{
                  textDecorationLine: "underline",
                  color: "#00A1FC",
                  fontSize: 16,
                  marginTop: 8,
                }}
              >
                Get from Playstore{" "}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                Linking.openURL(TextValues.appLinks.direct);
              }}
              style={{
                marginTop: 12,
                padding: 2,
              }}
            >
              <Text
                style={{
                  textDecorationLine: "underline",
                  color: "#00A1FC",
                  fontSize: 16,
                }}
              >
                Direct download{" "}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{ marginLeft: 16 }}>
            <View style={{ flexDirection: "row", marginTop: 16 }}>
              <Icon size={18} type="ionicon" name="logo-apple" />
              <Text style={{ marginLeft: 8 }}>iPhone</Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(TextValues.appLinks.appStore);
              }}
              style={{
                padding: 2,
                marginTop: 8,
              }}
            >
              <Text
                style={{
                  textDecorationLine: "underline",
                  color: "#00A1FC",
                  fontSize: 16,
                }}
              >
                Get from App Store{" "}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}
