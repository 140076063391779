import React, { useState, useEffect } from "react";
import Navigation from "./Navigation";

import { Provider } from "react-native-paper";
import { ActivityIndicator, View } from "react-native";
import { useFonts } from "expo-font";
import { initializeApp } from "firebase/app";
const config = {
  databaseURL: "https://your-database-name.firebaseio.com",
  apiKey: "AIzaSyAt7mZxw9bA5xcwsr6MJUtDUJD0hVI9XoA",
  authDomain: "spring-ship-233716.firebaseapp.com",
  projectId: "spring-ship-233716",
  storageBucket: "spring-ship-233716.appspot.com",
  messagingSenderId: "614671434091",
  appId: "1:614671434091:web:6f83ceb4b7ab47829f8c2e",
  measurementId: "G-20RRH8ZLZN",
};

initializeApp(config);

export default function App() {
  let [fontsLoaded] = useFonts({
    "Uber-Regular": require("./assets/fonts/UberMoveTextRegular.otf"),
    "Uber-Medium": require("./assets/fonts/UberMoveTextMedium.otf"),
    "Uber-Bold": require("./assets/fonts/UberMoveTextBold.otf"),
    "Uber-Light": require("./assets/fonts/UberMoveTextLight.otf"),
  });
  if (!fontsLoaded) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator color="orange" />
      </View>
    );
  }
  return (
    <Provider>
      <Navigation />
    </Provider>
  );
}
