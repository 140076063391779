import React, { useState, useEffect } from "react";

import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  StatusBar,
  Alert,
  TouchableOpacity,
  TextInput,
} from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { doc, updateDoc, getFirestore, getDoc } from "firebase/firestore";

import { Icon } from "react-native-elements";

const styles = StyleSheet.create({
  statusbar: {},
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function Admin({ navigation }) {
  const db = getFirestore();
  const [promo, setPromo] = useState("");

  const getPromo = async () => {
    const docRef = doc(db, "config", "promo");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setPromo(docSnap.data().value);
    }
  };

  useEffect(() => {
    getPromo();
  }, []);

  const saveAdmin = async () => {
    await AsyncStorage.setItem("@admin", "1");
  };

  const savePromo = async () => {
    const docRef = doc(db, "config", "promo");
    await updateDoc(docRef, {
      value: promo,
    });
    alert("Promo updated");
  };

  useEffect(() => {
    saveAdmin();
  }, []);

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor="orange" barStyle="dark-content" />
      <SafeAreaView>
        <Text
          style={{
            marginHorizontal: 20,
            marginTop: 24,
            fontSize: 20,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Drive to Own
          <Text style={{ fontSize: 18, opacity: 0.7 }}> Admin</Text>
        </Text>
        <TouchableOpacity onPress={() => navigation.navigate("Applications")}>
          <View
            style={{
              flexDirection: "row",
              padding: 20,
              backgroundColor: "orange",
              marginHorizontal: 20,
              borderRadius: 8,
              alignItems: "center",
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            <Icon name="list" />
            <Text style={{ fontSize: 18, marginLeft: 12 }}>
              Manage applications
            </Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          style={{}}
          onPress={() => {
            navigation.navigate("AddCar");
          }}
        >
          <View
            style={{
              flexDirection: "row",
              padding: 20,
              backgroundColor: "orange",
              marginHorizontal: 20,
              borderRadius: 8,
              alignItems: "center",
            }}
          >
            <Icon name="add" />
            <Text style={{ fontSize: 18, marginLeft: 12 }}>Add a new car</Text>
          </View>
        </TouchableOpacity>

        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            marginTop: 32,
          }}
        >
          <Text style={{ marginRight: 16 }}>Promo (%): </Text>
          <View style={{}}>
            <TextInput
              style={{
                outlineStyle: "none",
              }}
              value={promo}
              onChangeText={(text) => {
                setPromo(
                  text.replace(/[^0-9]/g, "").replace(/(\..*?)\..*/g, "$1")
                );
              }}
              placeholder="%"
            />
            <View
              style={{
                width: "100%",
                height: 1,
                backgroundColor: "silver",
                marginTop: 8,
              }}
            />
          </View>
          <TouchableOpacity
            onPress={() => {
              savePromo();
            }}
            style={{ padding: 4 }}
          >
            <Icon name="check" />
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    </View>
  );
}
