import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import FAQList from "./FAQs/List";

const styles = StyleSheet.create({});

export default function FAQs() {
  return (
    <View
      style={{
        flexDirection: "column",
        paddingTop: 16,
      }}
    >
      <View style={{ flexDirection: "row", marginHorizontal: 20 }}>
        <View style={{ flexDirection: "column" }}>
          <Text style={{ fontFamily: "Uber-Medium", fontSize: 24 }}>FAQs</Text>
          <Text
            style={{ fontFamily: "Uber-Light", fontSize: 18, maxWidth: 320 }}
          >
            Questions other customers are asking
          </Text>
        </View>
      </View>
      <FAQList />
    </View>
  );
}
