import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Icon } from "react-native-elements";
import { Button, TextInput } from "react-native-paper";
import { TextValues } from "../../constants/Strings";
import { useState, useRef } from "react";
import { LinearGradient } from "expo-linear-gradient";

const styles = StyleSheet.create({
  hero: {
    fontFamily: "Uber-Bold",
    fontSize: 32,
    textAlign: "center",
    maxWidth: 300,
    alignSelf: "center",
    marginTop: 8,
  },
  description: {
    fontFamily: "Uber-Light",
    fontSize: 18,
    marginTop: 16,
    textAlign: "center",
    maxWidth: 300,
    alignSelf: "center",
  },
});

export default function PreQualify({}) {
  const [checking, setChecking] = useState(false);

  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date();

  const [collectionDate, setCollectionDate] = useState("");

  const [date, setDate] = useState({
    dd: "",
    mm: "",
    yy: "",
  });
  const [msg, setMsg] = useState("");
  const [income, setIncome] = useState("");
  const onDateChange = (date, type) => {
    setCollectionDate(date._d);
  };

  const two = useRef(null);
  const three = useRef(null);
  return (
    <View
      // Button Linear Gradient

      start={{ x: 0, y: 0.5 }}
      end={{ x: 1, y: 0.5 }}
      style={{
        flexDirection: "column",
        padding: 30,
        backgroundColor: "blue",
        marginHorizontal: 20,
        borderRadius: 8,
        marginTop: 64,
        alignSelf: "center",
        maxWidth: 500,
        backgroundColor: "#FEEBD0",
      }}
    >
      {!checking && (
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <View
            style={{
              backgroundColor: "orange",
              height: 60,
              width: 60,
              borderRadius: 30,
              marginRight: 16,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              resizeMode="contain"
              style={{ height: 45, width: 45, tintColor: "white" }}
              source={require("../../assets/handshake.png")}
            />
          </View>
          <Text
            style={{
              fontFamily: "Uber-Regular",
              fontSize: 18,
              maxWidth: 350,
              textAlign: "center",
              marginTop: 16,
            }}
          >
            No Commitment, No Down Payment to begin. All Credit welcome. Browse
            vehicles based on your income.
          </Text>
        </View>
      )}

      {checking && (
        <View>
          <Text style={{ fontFamily: "Uber-Bold", fontSize: 16 }}>
            Driver's license first issue date:
          </Text>
          <Text style={{ fontFamily: "Uber-Light", marginBottom: 16 }}>
            Enter date in the format DD MM YYYY e.g 02 03 2013 for 2 February
            2013
          </Text>
          <View style={{ flexDirection: "row" }}>
            <TextInput
              placeholderTextColor="grey"
              value={date.dd}
              maxLength={2}
              theme={{ colors: { text: "black", primary: "blue" } }}
              style={{ width: 50, backgroundColor: "#FEEBD0", color: "black" }}
              outlineColor="blue"
              mode="flat"
              onChangeText={(text) => {
                if (parseInt(text) < 32) {
                  setDate((date) => ({ ...date, dd: text }));
                  if (text.length > 1) {
                    two.current.focus();
                  }
                } else {
                  setDate((date) => ({ ...date, dd: "" }));
                }
              }}
              placeholder="DD"
            />
            <TextInput
              value={date.mm}
              theme={{ colors: { text: "black", primary: "blue" } }}
              maxLength={2}
              outlineColor="blue"
              placeholderTextColor="grey"
              ref={two}
              mode="flat"
              style={{
                marginHorizontal: 8,
                width: 50,
                backgroundColor: "#FEEBD0",
              }}
              placeholder="MM"
              onChangeText={(text) => {
                if (parseInt(text) < 13) {
                  setDate((date) => ({ ...date, mm: text }));
                  if (text.length > 1) {
                    three.current.focus();
                  }
                } else {
                  setDate((date) => ({ ...date, mm: "" }));
                }
              }}
            />
            <TextInput
              value={date.yy}
              ref={three}
              theme={{ colors: { text: "black", primary: "blue" } }}
              maxLength={4}
              onChangeText={(text) => {
                setDate((date) => ({ ...date, yy: text }));
                if (text.length > 1) {
                }
              }}
              placeholderTextColor="grey"
              outlineColor="blue"
              mode="flat"
              style={{ width: 80, backgroundColor: "#FEEBD0" }}
              placeholder="YYYY"
            />
          </View>
        </View>
      )}

      {msg != "" && msg == "failed" && (
        <View
          style={{
            backgroundColor: "#FF8178",
            borderRadius: 8,
            padding: 16,
            marginVertical: 8,
          }}
        >
          <Text style={{ fontFamily: "Uber-Medium", color: "white" }}>
            We are sorry, you do not meet our minimum requirements.
          </Text>
        </View>
      )}

      {msg != "" && msg == "passed" && (
        <View
          style={{
            borderRadius: 8,
            padding: 16,
            marginVertical: 24,
            backgroundColor: "#00DD00",
          }}
        >
          <Text style={{ fontFamily: "Uber-Medium", color: "white" }}>
            Congratulations, you are pre-qualified to get a car!
          </Text>
        </View>
      )}
      {checking && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 24,
          }}
        >
          <View style={{ flex: 1, marginRight: 16 }}>
            <Text style={{ fontFamily: "Uber-Bold", fontSize: 16 }}>
              Gross Income {"(R)"}:
            </Text>

            <TextInput
              value={income}
              theme={{ colors: { text: "black", primary: "blue" } }}
              placeholderTextColor="grey"
              outlineColor="blue"
              mode="flat"
              style={{ backgroundColor: "#FEEBD0", width: 200, height: 50 }}
              onChangeText={(text) => {
                setIncome(
                  text.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                );
              }}
              placeholder="Enter gross income"
            />
          </View>
          <Button
            onPress={() => {
              if (income === "") {
                alert("Please enter your gross income");
                return;
              }
              if (date.dd.length < 2 || date.mm.length < 2 || date.yy < 4) {
                alert("Please enter a valid issue date. Use the format given.");
                return;
              }

              const secondDate = new Date(
                date.mm + "/" + date.dd + "/" + date.yy
              );

              const diffDays = Math.round(
                Math.abs((firstDate - secondDate) / oneDay)
              );

              if (diffDays < 3 * 365 || parseInt(income) < 15000) {
                setMsg("failed");
              } else {
                setMsg("passed");
              }
            }}
            color="black"
            style={{ alignSelf: "baseline" }}
            theme={{ roundness: 12 }}
            contentStyle={{ height: 55 }}
            mode="contained"
          >
            Check now
          </Button>
        </View>
      )}

      {!checking && (
        <TouchableOpacity
          onPress={() => {
            setChecking(true);
          }}
          style={{
            shadowColor: "orange",
            shadowOffset: { width: 2, height: 10 },
            shadowOpacity: 0.3,
            shadowRadius: 24,
            alignSelf: "center",
            backgroundColor: "white",
            paddingHorizontal: 16,
            borderRadius: 24,
            marginTop: 24,
            paddingVertical: 16,
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Text style={{ fontFamily: "Uber-Regular", fontSize: 16 }}>
            Get Pre-Qualified Now
          </Text>
          <Icon name="chevron-right" />
        </TouchableOpacity>
      )}
    </View>
  );
}
