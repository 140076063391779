import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Icon } from "react-native-elements";
import { Menu, Divider, Provider } from "react-native-paper";
import { useState } from "react";

import { TextValues } from "../../constants/Strings";

const styles = StyleSheet.create({
  logo: { height: 45, width: 100 },
  logoText: {
    fontFamily: "Uber-Bold",
    fontSize: 16,
    marginLeft: 12,
  },
  downloadButtonContainer: {
    backgroundColor: "#ffddb0",
    borderRadius: 24,
    paddingHorizontal: 12,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 8,
  },
  downloadButtonText: {
    marginLeft: 8,
    fontSize: 15,
    color: "black",
    fontFamily: "Uber-Medium",
  },
});

export default function Header({ scrollV, carV, faqV, len }) {
  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 20,
        shadowColor: "orange",
        shadowOffset: { width: 2, height: 10 },
        shadowOpacity: 0.1,
        shadowRadius: 24,
        alignItems: "center",
        position: "fixed",
        right: 0,
        left: 0,
        backgroundColor: "#F6F6F6",
      }}
    >
      <TouchableOpacity
        onPress={() => {
          scrollV.current.scrollTo({
            x: 0,
            y: 0,
            animated: true,
          });
        }}
        style={{ flexDirection: "row", alignItems: "center" }}
      >
        <Image
          resizeMode="contain"
          style={styles.logo}
          source={require("../../assets/images/logo-main.png")}
        />
      </TouchableOpacity>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(TextValues.links.whatsapp);
          }}
          style={{}}
        >
          <Icon name="logo-whatsapp" type="ionicon" />
        </TouchableOpacity>

        <TouchableOpacity
          style={{ marginHorizontal: 20 }}
          onPress={() => {
            // scrollV.current.scrollToEnd({ animated: true });
            let added = len > 0 ? 3500 : 0;
            scrollV.current.scrollTo({
              x: 0,
              y: faqV.y + added,
              animated: true,
            });
          }}
        >
          <Icon name="help-circle-outline" size={26} type="ionicon" />
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(TextValues.links.webapp);
          }}
          style={styles.downloadButtonContainer}
        >
          <Icon color="black" size={18} type="ionicon" name="log-in" />
          <Text style={styles.downloadButtonText}>Login</Text>
        </TouchableOpacity>
        {false && (
          <TouchableOpacity
            onPress={() => {
              scrollV.current.scrollTo({
                x: 0,
                y: carV.y + 500,
                animated: true,
              });
            }}
          >
            <Icon name="car-outline" size={26} type="ionicon" />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}
