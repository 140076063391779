import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import { Button } from "react-native-paper";
import { TextValues } from "../../constants/Strings";

const styles = StyleSheet.create({
  heading: {
    fontFamily: "Uber-Bold",
    fontSize: 56,
    alignSelf: "center",
    marginBottom: 72,
  },
  boxHeading: {
    fontFamily: "Uber-Bold",
    fontSize: 28,
    maxWidth: 280,
  },
  boxDescription: {
    fontFamily: "Uber-Light",
    fontSize: 24,
    marginTop: 8,
    textAlign: "justify",
    maxWidth: 280,
  },
  box: { flexDirection: "column", marginBottom: 64 },
  iconContainer: {
    backgroundColor: "orange",
    height: 100,
    width: 100,
    justifyContent: "center",
    borderRadius: 50,
    alignItems: "center",
    marginLeft: 16,
  },
});

export default function HowItWorks() {
  return (
    <View style={{ flexDirection: "column", paddingHorizontal: 32 }}>
      <Text style={styles.heading}>How does it work?</Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginTop: 16,
        }}
      >
        <View style={{ flexDirection: "column" }}>
          <View style={styles.box}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.boxHeading}>
                  {TextValues.howItWorks[0].title}
                </Text>
                <Text style={styles.boxDescription}>
                  {TextValues.howItWorks[0].description}
                </Text>
              </View>
              <View style={styles.iconContainer}>
                <Icon size={28} name="document-text-outline" type="ionicon" />
              </View>
            </View>
          </View>
          <View style={styles.box}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.boxHeading}>
                  {TextValues.howItWorks[1].title}
                </Text>
                <Text style={styles.boxDescription}>
                  {TextValues.howItWorks[1].description}
                </Text>
              </View>
              <View style={styles.iconContainer}>
                <Icon size={28} name="car-outline" type="ionicon" />
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            width: 250,
            height: 310,
            borderBottomEndRadius: 64,
            backgroundColor: "#9CB1C1",
            borderBottomStartRadius: 48,
            borderTopStartRadius: 24,
            borderTopEndRadius: 32,
          }}
        >
          <Image
            resizeMode="contain"
            style={{
              height: 500,
              width: 400,
              position: "absolute",
              bottom: -100,
              alignSelf: "center",
            }}
            source={require("../../assets/images/drawing.png")}
          />
        </View>
        <View style={{ flexDirection: "column" }}>
          <View style={styles.box}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.boxHeading}>
                  {TextValues.howItWorks[2].title}
                </Text>
                <Text style={styles.boxDescription}>
                  {TextValues.howItWorks[2].description}
                </Text>
              </View>
              <View style={styles.iconContainer}>
                <Icon size={28} name="cloud-download-outline" type="ionicon" />
              </View>
            </View>
          </View>
          <View style={styles.box}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <View style={{ flexDirection: "column" }}>
                <Text style={styles.boxHeading}>
                  {TextValues.howItWorks[3].title}
                </Text>
                <Text style={styles.boxDescription}>
                  {TextValues.howItWorks[3].description}
                </Text>
              </View>
              <View style={styles.iconContainer}>
                <Icon
                  size={28}
                  name="shield-checkmark-outline"
                  type="ionicon"
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}
