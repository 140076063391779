import { StatusBar } from "expo-status-bar";
import { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Icon } from "react-native-elements";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { useNavigation } from "@react-navigation/native";
const styles = StyleSheet.create({});

export default function CarList({ carData }) {
  const [admin, setAdmin] = useState(false);

  const getAdmin = async () => {
    let auth = await AsyncStorage.getItem("@admin");
    auth === "1" && setAdmin(true);
  };
  useEffect(() => {
    getAdmin();
  }, []);

  const navigation = useNavigation();
  const renderItem = ({ item }) => (
    <TouchableOpacity
      style={{
        marginHorizontal: 8,
        marginVertical: 24,
        width: "48%",
      }}
      onPress={async () => {
        await AsyncStorage.setItem("@car", JSON.stringify(item));
        navigation.navigate("Car", { car: item });
      }}
    >
      <View
        style={{
          backgroundColor: "white",
          paddingHorizontal: 16,
          borderRadius: 12,
          flexDirection: "column",
          elevation: 16,
          shadowColor: "black",
          shadowOffset: { width: 2, height: 10 },
          shadowOpacity: 0.1,
          shadowRadius: 24,
          paddingTop: 16,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View>
            <Text
              numberOfLines={1}
              style={{ fontFamily: "Uber-bold", fontSize: 16, maxWidth: 160 }}
            >
              {item.name}
            </Text>

            <Text style={{ fontFamily: "Uber-Light" }}>{item.model}</Text>
          </View>
          <View
            style={{
              paddingHorizontal: 12,
              borderRadius: 32,
              flexDirection: "row",
            }}
          >
            <Text style={{ fontFamily: "Uber-Bold", fontSize: 15 }}>
              R{item.subscription}
            </Text>
            <Text style={{ fontFamily: "Uber-Light", marginTop: 8 }}>
              {" "}
              monthly
            </Text>
          </View>
        </View>
        <Image
          style={{ width: "90%", height: 100, marginVertical: 24 }}
          resizeMode="contain"
          source={{ uri: item.url }}
        />
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon color="orange" name="cog-outline" type="ionicon" />
            <Text style={{ fontFamily: "Uber-Light" }}>
              {item.transmission}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              marginHorizontal: 24,
            }}
          >
            <Icon color="orange" name="local-gas-station" type="material" />
            <Text style={{ fontFamily: "Uber-Light" }}>{item.fuel}</Text>
          </View>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon color="orange" name="car-outline" type="ionicon" />
            <Text style={{ fontFamily: "Uber-Light" }}>{item.bodyType}</Text>
          </View>
        </View>
        {admin && (
          <TouchableOpacity
            onPress={async () => {
              await AsyncStorage.setItem("@car", JSON.stringify(item));
              navigation.navigate("Update");
            }}
            style={{ position: "absolute", left: 20, bottom: 10 }}
          >
            <View style={{ padding: 8 }}>
              <Icon name="edit" />
            </View>
          </TouchableOpacity>
        )}
        <TouchableOpacity
          onPress={async () => {
            await AsyncStorage.setItem("@car", JSON.stringify(item));
            navigation.navigate("Car", { car: item });
          }}
          style={{
            alignSelf: "flex-end",
            marginTop: 16,
            marginRight: -16,
          }}
        >
          <View
            style={{
              borderBottomEndRadius: 16,
              borderTopStartRadius: 16,
              paddingHorizontal: 28,
              paddingVertical: 16,
              backgroundColor: "#292A2A",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Uber-Regular",
                color: "white",
                marginRight: 8,
              }}
            >
              Get Car
            </Text>
            <Icon color="silver" name="chevron-right" />
          </View>
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
  return (
    <View
      style={{
        flexDirection: "column",
        paddingTop: 32,
      }}
    >
      <FlatList
        numColumns={2}
        contentContainerStyle={{ marginBottom: 32 }}
        data={carData}
        keyExtractor={(item) => {
          return item.id;
        }}
        renderItem={renderItem}
      />
    </View>
  );
}
