//

import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Icon } from "react-native-elements";

import { TextValues } from "../../constants/Strings";

const styles = StyleSheet.create({
  copyright: {
    fontFamily: "Uber-Light",
    color: "white",
    opacity: 0.8,
    fontSize: 16,
  },
  menuText: {
    fontFamily: "Uber-Light",
    fontSize: 16,
    color: "silver",
  },
  menuText2: {
    fontFamily: "Uber-Light",
    fontSize: 16,
    color: "silver",
    marginLeft: 8,
    maxWidth: 250,
  },
  menuTextContainer: {
    marginTop: 12,
  },
  contactTextContainer: {
    flexDirection: "row",
    marginTop: 12,
  },
});

export default function Footer() {
  return (
    <View
      style={{
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 20,
        backgroundColor: "#24272F",
        marginTop: 64,
        marginHorizontal: 0,
      }}
    >
      <View style={{ flexDirection: "column" }}>
        <View style={{ marginVertical: 16 }}>
          <Text
            style={{ fontFamily: "Uber-Bold", fontSize: 16, color: "white" }}
          >
            Contact
          </Text>

          <TouchableOpacity style={styles.contactTextContainer}>
            <Icon size={18} color="silver" name="email" />
            <Text style={styles.menuText2}>info@drivetoown.co.za</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.contactTextContainer}>
            <Icon
              size={18}
              color="silver"
              name="location-outline"
              type="ionicon"
            />
            <Text style={styles.menuText2}>
              Drive to Own (PTY) LTD 1st Floor Saligna Crescent International
              Business Gateway New Road, Carlswald, Midrand Gauteng 1684
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{ marginVertical: 16 }}>
          <Text
            style={{ fontFamily: "Uber-Bold", fontSize: 16, color: "white" }}
          >
            Legal matters
          </Text>

          <TouchableOpacity
            onPress={() => {
              Linking.openURL(TextValues.links.terms);
            }}
            style={styles.menuTextContainer}
          >
            <Text
              style={[styles.menuText, { textDecorationLine: "underline" }]}
            >
              Terms of use
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              Linking.openURL(TextValues.links.policy);
            }}
            style={styles.menuTextContainer}
          >
            <Text
              style={[styles.menuText, { textDecorationLine: "underline" }]}
            >
              Policies
            </Text>
          </TouchableOpacity>
        </View>

        <View
          style={{
            flexDirection: "row",
            marginVertical: 16,
            alignItems: "center",
          }}
        >
          <Image
            style={{ height: 65, width: 90 }}
            resizeMode="contain"
            source={require("../../assets/grindstone.png")}
          />
          <Image
            style={{ height: 65, width: 90 }}
            resizeMode="contain"
            source={require("../../assets/alphacode.png")}
          />
          <Text style={{ fontFamily: "Uber-Light", color: "white" }}>
            We're part of the RMI{"\n"}Alphacode program
          </Text>
        </View>
        {false && (
          <View style={{ marginVertical: 16 }}>
            <Text style={{ fontFamily: "Uber-Medium", color: "silver" }}>
              Registered Credit Provider
            </Text>

            <Text style={[styles.menuText]}>NCRCP13055</Text>
          </View>
        )}
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 36,
        }}
      >
        <Text style={styles.copyright}>
          © {new Date().getFullYear().toString()} Drive to Own.
        </Text>
        {false && (
          <View style={{ flexDirection: "row" }}>
            <Icon
              color="silver"
              style={{ marginHorizontal: 16 }}
              type="ionicon"
              name="logo-whatsapp"
            />
            <Icon color="silver" name="facebook" />
          </View>
        )}
      </View>
    </View>
  );
}
