import React, { useState } from "react";

import { StyleSheet, Text, View, SafeAreaView } from "react-native";
import { Button } from "react-native-paper";
import { Icon } from "react-native-elements";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function Complete({ navigation }) {
  return (
    <View style={styles.container}>
      <SafeAreaView>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "black",
              height: 40,
              width: 40,
              borderRadius: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon color="#5AFF7E" name="check" />
          </View>
          <Text
            style={{
              fontSize: 20,
              marginTop: 12,
              fontFamily: "Uber-Bold",
              fontSize: 32,
            }}
          >
            We are done here
          </Text>

          <Text
            style={{
              margin: 20,
              textAlign: "center",
              fontSize: 24,
              fontFamily: "Uber-Regular",
              maxWidth: 400,
            }}
          >
            An email with the Drive to Own Agreement will be sent to you if your
            application gets approved. Please review and sign electronically
            after receiving it. Once signed, it may take up to 48hrs to make
            your car available.
          </Text>
          <Button
            onPress={() => navigation.navigate("Home")}
            theme={{ roundness: 24 }}
            contentStyle={{ height: 55 }}
            style={{ width: 120 }}
            mode="contained"
            color="black"
            icon="chevron-right"
          >
            Done
          </Button>
        </View>
      </SafeAreaView>
    </View>
  );
}
