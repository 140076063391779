import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import DesktopContainer from "./components/desktop/DesktopContainer";
import MobileContainer from "./components/mobile/MobileContainer";

import TabletContainer from "./components/tablet/TabletContainer";
import MediaQuery from "react-responsive";
import { useState, useEffect } from "react";

import { collection, getDocs, getFirestore } from "firebase/firestore";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    flexDirection: "column",
  },
});

export default function App() {
  const db = getFirestore();

  const [carData, setCarData] = useState([]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getCars = async () => {
    const querySnapshot = await getDocs(collection(db, "inventory"));
    const temp = [];
    querySnapshot.forEach((doc) => {
      temp.push({
        ...doc.data(),
        id: doc.id,
        subscription: numberWithCommas(parseInt(doc.data().subscription)),
      });
    });
    setCarData(temp);
  };

  useEffect(() => {
    getCars();
  }, []);

  return (
    <View style={styles.container}>
      <MediaQuery maxWidth={500}>
        <MobileContainer carData={carData} />
      </MediaQuery>
      <MediaQuery minWidth={501} maxWidth={1199}>
        <TabletContainer carData={carData} />
      </MediaQuery>

      <MediaQuery minWidth={1200}>
        <DesktopContainer carData={carData} />
      </MediaQuery>
    </View>
  );
}
