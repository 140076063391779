import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import MediaQuery from "react-responsive";
import { useFonts } from "expo-font";
import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { Icon } from "react-native-elements";
import { Divider } from "react-native-paper";
import { Button } from "react-native-paper";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    flexDirection: "column",
    padding: 16,
  },
  stepBox: {
    borderRadius: 12,
    shadowColor: "orange",
    shadowOffset: { width: 2, height: 10 },
    shadowOpacity: 0.2,
    shadowRadius: 12,
    alignItems: "center",
    paddingVertical: 20,
    paddingHorizontal: 12,
    marginRight: 16,

    backgroundColor: "#fcf8f2",
  },
  stepHeader: {
    fontFamily: "Uber-Bold",
    marginTop: 8,
  },
  stepDescription: {
    fontFamily: "Uber-Regular",
    maxWidth: 140,
    textAlign: "center",
    marginTop: 6,
  },
  stepBox1: {
    borderRadius: 12,
    shadowColor: "orange",
    shadowOffset: { width: 5, height: 19 },
    shadowOpacity: 0.2,
    shadowRadius: 24,
    alignItems: "center",
    paddingVertical: 20,
    paddingHorizontal: 12,
    width: "100%",
    backgroundColor: "#fcf8f2",
  },
  stepHeader1: {
    fontFamily: "Uber-Bold",
    marginTop: 8,
  },
  stepDescription1: {
    fontFamily: "Uber-Regular",
    maxWidth: 190,
    textAlign: "center",
    marginTop: 6,
    fontSize: 16,
  },
});

export default function Car({ navigation }) {
  const [car, setCar] = useState({});
  const getCar = async () => {
    let car = await AsyncStorage.getItem("@car");
    car = JSON.parse(car);
    setCar({ ...car, subscription: car.subscription.replace(",", "") });
  };
  useEffect(() => {
    getCar();
  }, []);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const [oneVisible, setOneVisible] = useState(true);
  const [twoVisible, setTwoVisible] = useState(false);
  const [threeVisible, setThreeVisible] = useState(false);

  const changeVisible = (card) => {
    switch (card) {
      case "one":
        setOneVisible(true);
        setTwoVisible(false);
        setThreeVisible(false);
        break;

      case "two":
        setOneVisible(false);
        setTwoVisible(true);
        setThreeVisible(false);
        break;
      case "three":
        setOneVisible(false);
        setTwoVisible(false);
        setThreeVisible(true);
        break;
    }
  };
  return (
    <ScrollView style={styles.container}>
      <MediaQuery maxWidth={899}>
        <View />
        {car != {} && (
          <View
            style={{
              flexDirection: "column",
              alignSelf: "center",
              alignItems: "center",
              padding: 20,
              borderWidth: 1,
              borderColor: "#E2E2E2",
              backgroundColor: "white",
              borderRadius: 12,
              marginBottom: 124,
            }}
          >
            <View style={{ width: "100%" }}>
              <Button
                onPress={() => navigation.navigate("Home")}
                icon="chevron-left"
                color="black"
                style={{ alignSelf: "center" }}
              >
                Back to cars
              </Button>
              <Image
                source={{ uri: car.url }}
                resizeMode="contain"
                style={{ height: 200, width: "100%", borderRadius: 12 }}
              />
              <Text
                style={{ marginTop: 32, fontFamily: "Uber-Bold", fontSize: 18 }}
              >
                Steps to get your car
              </Text>
              <View
                style={{
                  flexDirection: "column",
                  marginTop: 16,
                  width: "100%",
                }}
              >
                {oneVisible && (
                  <View style={styles.stepBox1}>
                    <Icon name="clipboard-outline" type="ionicon" />
                    <Text style={styles.stepHeader1}>1. Apply online</Text>
                    <Text style={styles.stepDescription1}>
                      Select your vehicle and apply online
                    </Text>
                  </View>
                )}
                {twoVisible && (
                  <View style={styles.stepBox1}>
                    <Icon name="cloud-upload-outline" type="ionicon" />
                    <Text style={styles.stepHeader1}>2. Upload documents</Text>
                    <Text style={styles.stepDescription1}>
                      Submit the required documents
                    </Text>
                  </View>
                )}
                {threeVisible && (
                  <View style={styles.stepBox1}>
                    <Icon name="car-outline" type="ionicon" />
                    <Text style={styles.stepHeader1}>3. Collect your car</Text>
                    <Text style={styles.stepDescription1}>
                      You collect your car from any Europcar branch nearest to
                      you.
                    </Text>
                  </View>
                )}
                <View
                  style={{
                    flexDirection: "row",
                    alignSelf: "center",
                    marginTop: 48,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => changeVisible("one")}
                    style={{
                      marginHorizontal: 4,
                    }}
                  >
                    <View
                      style={{
                        height: 20,
                        width: 20,
                        borderRadius: 10,
                        backgroundColor: "black",
                        borderColor: "black",
                        marginHorizontal: 8,
                        borderWidth: 1,
                        backgroundColor: oneVisible ? "black" : "white",
                      }}
                    ></View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => changeVisible("two")}
                    style={{
                      marginHorizontal: 4,
                    }}
                  >
                    <View
                      style={{
                        height: 20,
                        width: 20,
                        borderRadius: 10,
                        borderColor: "black",
                        marginHorizontal: 8,
                        borderWidth: 1,
                        backgroundColor: twoVisible ? "black" : "white",
                      }}
                    ></View>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => changeVisible("three")}
                    style={{
                      marginHorizontal: 4,
                    }}
                  >
                    <View
                      style={{
                        height: 20,
                        width: 20,
                        borderRadius: 10,
                        borderColor: "black",
                        marginHorizontal: 8,
                        borderWidth: 1,
                        backgroundColor: threeVisible ? "black" : "white",
                      }}
                    ></View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <View
              style={{
                padding: 10,
                backgroundColor: "white",
                borderRadius: 12,
                width: "100%",
                marginTop: 32,
              }}
            >
              <Text style={{ fontFamily: "Uber-Bold", fontSize: 18 }}>
                {car.name}
              </Text>
              <Text style={{ fontFamily: "Uber-Light", fontSize: 16 }}>
                {car.model}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Icon color="black" name="cog-outline" type="ionicon" />
                  <Text style={{ fontFamily: "Uber-Medium" }}>
                    {car.transmission}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    marginHorizontal: 24,
                  }}
                >
                  <Icon
                    color="black"
                    name="local-gas-station"
                    type="material"
                  />
                  <Text style={{ fontFamily: "Uber-Medium" }}>{car.fuel}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Icon color="black" name="car-outline" type="ionicon" />
                  <Text style={{ fontFamily: "Uber-Medium" }}>
                    {car.bodyType}
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  fontSize: 16,
                  marginTop: 32,
                }}
              >
                Drive to Own
              </Text>
              <View
                style={{
                  padding: 12,
                  borderRadius: 8,
                  borderColor: "#E2E2E2",
                  alignItems: "center",
                  borderWidth: 0.8,
                  marginTop: 8,
                }}
              >
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    {numberWithCommas(parseInt(car.freeKM))}
                  </Text>{" "}
                  km free included
                </Text>
                <Text style={{ fontFamily: "Uber-Regular", marginVertical: 8 }}>
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    R{car.pricePerKm}
                  </Text>{" "}
                  per extra KM
                </Text>
                <Text
                  style={{ fontFamily: "Uber-Regular", textAlign: "center" }}
                >
                  Own the car after driving
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    {" "}
                    {numberWithCommas(parseInt(car.kmToOwn))}
                  </Text>
                  KM
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  fontSize: 16,
                  marginTop: 24,
                }}
              >
                What's included?
              </Text>
              <Text
                style={{ fontFamily: "Uber-Light", fontSize: 16, marginTop: 8 }}
              >
                - Service and Maintenance{"\n"}- Insurance{"\n"}- Free{" "}
                {numberWithCommas(parseInt(car.freeKM))}KM
                {"\n"}- 24/7 Road Assistance
              </Text>
              <Divider style={{ marginVertical: 16 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ marginRight: 16 }}>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    Monthly subscription
                  </Text>
                  <Text
                    style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}
                  >
                    Once-off activation fee
                  </Text>
                  <Text style={{ fontFamily: "Uber-Bold" }}>Total</Text>
                </View>
                <View>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    R{numberWithCommas(parseFloat(car.subscription).toFixed(0))}
                  </Text>
                  <Text
                    style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}
                  >
                    R
                    {numberWithCommas(parseFloat(car.activationFee).toFixed(0))}
                  </Text>

                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    R
                    {numberWithCommas(
                      (
                        parseFloat(car.activationFee) +
                        parseFloat(car.subscription)
                      ).toFixed(0)
                    )}
                  </Text>
                </View>
              </View>
              <Button
                onPress={() => navigation.navigate("Apply")}
                theme={{ roundness: 12 }}
                style={{ marginTop: 16 }}
                contentStyle={{ height: 50 }}
                mode="contained"
                color="black"
              >
                Continue
              </Button>
            </View>
          </View>
        )}
      </MediaQuery>
      <MediaQuery minWidth={900}>
        <View />
        {car != {} && (
          <View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              alignItems: "center",
              padding: 20,
              borderWidth: 1,
              borderColor: "#E2E2E2",
              backgroundColor: "white",
              borderRadius: 12,
            }}
          >
            <View>
              <Button
                onPress={() => navigation.navigate("Home")}
                icon="chevron-left"
                color="black"
                style={{ alignSelf: "center" }}
              >
                Back to cars
              </Button>
              <Image
                source={{ uri: car.url }}
                resizeMode="contain"
                style={{ height: 400, width: 400, borderRadius: 12 }}
              />
              <Text
                style={{ marginTop: 32, fontFamily: "Uber-Bold", fontSize: 24 }}
              >
                Steps to get your car
              </Text>
              <View
                style={{ flexDirection: "row", marginTop: 8, flexWrap: "wrap" }}
              >
                <View style={styles.stepBox}>
                  <Icon name="clipboard-outline" type="ionicon" />
                  <Text style={styles.stepHeader}>1. Apply online</Text>
                  <Text style={styles.stepDescription}>
                    Select your vehicle and apply online
                  </Text>
                </View>
                <View style={styles.stepBox}>
                  <Icon name="cloud-upload-outline" type="ionicon" />
                  <Text style={styles.stepHeader}>2. Upload documents</Text>
                  <Text style={styles.stepDescription}>
                    Submit the required documents
                  </Text>
                </View>
                <View style={styles.stepBox}>
                  <Icon name="car-outline" type="ionicon" />
                  <Text style={styles.stepHeader}>3. Collect your car</Text>
                  <Text style={styles.stepDescription}>
                    You collect your car from any Europcar branch nearest to
                    you.
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                padding: 20,
                borderWidth: 1,
                borderColor: "#E2E2E2",
                backgroundColor: "white",
                borderRadius: 12,
                height: "100%",
              }}
            >
              <Text style={{ fontFamily: "Uber-Bold", fontSize: 18 }}>
                {car.name}
              </Text>
              <Text style={{ fontFamily: "Uber-Light", fontSize: 16 }}>
                {car.model}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Icon color="black" name="cog-outline" type="ionicon" />
                  <Text style={{ fontFamily: "Uber-Medium" }}>
                    {car.transmission}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    marginHorizontal: 24,
                  }}
                >
                  <Icon
                    color="black"
                    name="local-gas-station"
                    type="material"
                  />
                  <Text style={{ fontFamily: "Uber-Medium" }}>{car.fuel}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Icon color="black" name="car-outline" type="ionicon" />
                  <Text style={{ fontFamily: "Uber-Medium" }}>
                    {car.bodyType}
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  fontSize: 16,
                  marginTop: 32,
                }}
              >
                Drive to Own
              </Text>
              <View
                style={{
                  padding: 12,
                  borderRadius: 8,
                  borderColor: "#E2E2E2",
                  alignItems: "center",
                  borderWidth: 0.8,
                  marginTop: 8,
                }}
              >
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    {numberWithCommas(parseInt(car.freeKM))}
                  </Text>{" "}
                  km free included
                </Text>
                <Text style={{ fontFamily: "Uber-Regular", marginVertical: 8 }}>
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    R{car.pricePerKm}
                  </Text>{" "}
                  per extra KM
                </Text>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  Own the car after driving
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    {" "}
                    {numberWithCommas(parseInt(car.kmToOwn))}
                  </Text>
                  KM
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  fontSize: 16,
                  marginTop: 24,
                }}
              >
                What's included?
              </Text>
              <Text
                style={{ fontFamily: "Uber-Light", fontSize: 16, marginTop: 8 }}
              >
                - Service and Maintenance{"\n"}- Insurance{"\n"}- Free{" "}
                {numberWithCommas(parseInt(car.freeKM))}KM
                {"\n"}- 24/7 Road Assistance
              </Text>
              <Divider style={{ marginVertical: 16 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ marginRight: 16 }}>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    Monthly subscription
                  </Text>
                  <Text
                    style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}
                  >
                    Once-off activation fee
                  </Text>
                  <Text style={{ fontFamily: "Uber-Bold" }}>Total</Text>
                </View>
                <View>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    R{numberWithCommas(parseFloat(car.subscription).toFixed(0))}
                  </Text>
                  <Text
                    style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}
                  >
                    R
                    {numberWithCommas(parseFloat(car.activationFee).toFixed(0))}
                  </Text>

                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    R
                    {numberWithCommas(
                      (
                        parseFloat(car.activationFee) +
                        parseFloat(car.subscription)
                      ).toFixed(0)
                    )}
                  </Text>
                </View>
              </View>
              <Button
                onPress={() => navigation.navigate("Apply")}
                theme={{ roundness: 12 }}
                style={{ marginTop: 16 }}
                contentStyle={{ height: 50 }}
                mode="contained"
                color="black"
              >
                Continue
              </Button>
            </View>
          </View>
        )}
      </MediaQuery>
    </ScrollView>
  );
}
