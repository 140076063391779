import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import Home from "./Home";
import Car from "./Car";
import Apply from "./Apply";
import Complete from "./Complete";

import Admin from "./Admin";
import AddCar from "./AddCar";
import Update from "./Update";
import Applications from "./Applications";
import Application from "./Application";
import Agreement from "./Agreement";
import Policy from "./Policy";
import Terms from "./Terms";

const linking = {
  config: {
    screens: {
      Home: "Home",
      Agreement: "Agreement",
      Terms: "Terms",
      Application: "Application",
      Applications: "Applications",
      Update: "Update",
      Admin: "Admin",
      AddCar: "AddCar",
      Apply: "Apply",
      Car: "Car",
      Complete: "Complete",
      Policy: "Policy",
      Terms: "Terms",
    },
  },
};

const Stack = createNativeStackNavigator();

export default function App() {
  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="Home" component={Home} />
        <Stack.Screen name="Application" component={Application} />
        <Stack.Screen name="Applications" component={Applications} />
        <Stack.Screen name="Admin" component={Admin} />
        <Stack.Screen name="AddCar" component={AddCar} />
        <Stack.Screen name="Complete" component={Complete} />
        <Stack.Screen name="Apply" component={Apply} />
        <Stack.Screen name="Car" component={Car} />
        <Stack.Screen name="Update" component={Update} />
        <Stack.Screen name="Agreement" component={Agreement} />

        <Stack.Screen name="Policy" component={Policy} />
        <Stack.Screen name="Terms" component={Terms} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
