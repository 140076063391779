import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Icon } from "react-native-elements";
import { Button } from "react-native-paper";
import { TextValues } from "../../constants/Strings";

const styles = StyleSheet.create({
  hero: {
    fontFamily: "Uber-Bold",
    fontSize: 64,
  },
  description: {
    fontFamily: "Uber-Light",
    fontSize: 32,
    marginTop: 32,
  },
});

export default function Hero({ scrollV, carV }) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 72,
      }}
    >
      <View style={{ flexDirection: "column", maxWidth: 500 }}>
        <Text style={styles.hero}>{TextValues.hero}</Text>
        <Text style={styles.description}>{TextValues.heroDescription}</Text>
        <View style={{ flexDirection: "row", marginTop: 32 }}>
          <Button
            color="black"
            icon="car"
            mode="contained"
            contentStyle={{ height: 65 }}
            theme={{ roundness: 32 }}
            onPress={() =>
              scrollV.current.scrollTo({
                x: 0,
                y: carV.y + 500,
                animated: true,
              })
            }
          >
            Get my car now
          </Button>
          {false && (
            <Button
              color="green"
              icon="whatsapp"
              contentStyle={{ height: 65 }}
              theme={{ roundness: 32 }}
              style={{ marginLeft: 12 }}
              onPress={() => {
                Linking.openURL(TextValues.links.whatsapp);
              }}
            >
              send an inquiry
            </Button>
          )}
        </View>
      </View>
      <View style={{ flexDirection: "column" }}>
        <Image
          style={{ height: 600, width: 700 }}
          resizeMode="contain"
          source={require("../../assets/images/car.png")}
        />
      </View>
    </View>
  );
}
