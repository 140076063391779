import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  TextInput,
  ScrollView,
  SectionList,
} from "react-native";
import MediaQuery from "react-responsive";
import { useFonts } from "expo-font";
import uuid from "react-native-uuid";

import { createElement } from "react-native-web";
import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Icon } from "react-native-elements";
import { Divider } from "react-native-paper";
import { Button } from "react-native-paper";
import {
  collection,
  getFirestore,
  addDoc,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import * as DocumentPicker from "expo-document-picker";

import * as ImagePicker from "expo-image-picker";

import CalendarPicker from "react-native-calendar-picker";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    paddingHorizontal: 24,
    paddingVertical: 64,
  },
  stepBox: {
    borderRadius: 12,
    shadowColor: "blue",
    shadowOffset: { width: 2, height: 10 },
    shadowOpacity: 0.2,
    shadowRadius: 12,
    alignItems: "center",
    paddingVertical: 20,
    paddingHorizontal: 12,
    marginRight: 16,
  },
  stepHeader: {
    fontFamily: "Uber-Bold",
    marginTop: 8,
  },
  stepDescription: {
    fontFamily: "Uber-Regular",
    maxWidth: 140,
    textAlign: "center",
    marginTop: 6,
  },
});

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default function Apply({ navigation }) {
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const [car, setCar] = useState({});
  const [promo, setPromo] = useState(0);
  const getCar = async () => {
    let car = await AsyncStorage.getItem("@car");
    car = JSON.parse(car);

    setCar({ ...car, subscription: car.subscription.replace(",", "") });
  };
  useEffect(() => {
    getCar();
    getPromo();
  }, []);

  const [displayCalendar, setDisplayCalendar] = useState(false);

  const onDateChange = (date, type) => {
    setCollectionDate(date._d);
    setDisplayCalendar(false);
  };

  const [collectionDate, setCollectionDate] = useState("");

  const [profile, setProfile] = useState({
    name: "",
    phone: "",
    id: "",
    email: "",
    income: "",
  });

  const [documents, setDocs] = useState({
    license: null,
    bankStatement: null,
    payslip: null,
    id: null,
  });

  const [formats, setFormats] = useState({
    license: "",
    bankStatement: "",
    payslip: "",
    id: "",
  });

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const pickImage = async (type) => {
    let result = await DocumentPicker.getDocumentAsync({
      multiple: false,
      type: ["image/png", "application/pdf", "image/jpeg"],
    });

    if (!result.cancelled) {
      setDocs((documents) => ({ ...documents, [type]: result.uri }));
      setFormats((formats) => ({ ...formats, [type]: result.mimeType }));
    }
  };

  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      multiple: false,
    });
    setDocs((documents) => ({ ...documents, bankStatement: result.uri }));
  };

  const db = getFirestore();

  const getPromo = async () => {
    const docRef = doc(db, "config", "promo");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setPromo(docSnap.data().value);
    }
  };

  const saveApplication = async (appID, links) => {
    await addDoc(collection(db, "applications"), {
      ...profile,
      links: links,
      car: car,
      collectionDate: collectionDate.toDateString(),
      location: location,
      date: new Date().toDateString(),
      timestamp: Timestamp.fromDate(new Date()),
      formats: formats,
      docPath: appID,
    });
    navigation.navigate("Complete");
  };

  const DATA = [
    {
      title: "Airports",
      data: [
        "Johannesburg OR Tambo International Airport",
        "Cape Town International Airport",
        "King Shaka International Airport",
        "Lanseria Airport",
        "Bloemfontein Airport",
        "East London Airport",
        "George Airport",
        "Kimberley Airport",
        "Kruger Mpumalanga Int Airport",
        "Polokwane Airport",
        "Pietermaritzburg Airport",
        "Port Elizabeth Airport",
        "Richards Bay Airport",
        "Umtata Airport",
        "Upington Airport",
        "Wonderboom Airport",
      ],
    },
    {
      title: "Gauteng",
      data: [
        "Braamfontein",
        "Centurion",
        "Vanderbijlpark",
        "Fourways",
        "Braamfontein-Parkstation",
        "Kramerville",
        "Meadowdale",
        "Mogale City (Krugersdorp Agency)",
        "Hatfield",
        "Sandton",
      ],
    },
    {
      title: "Western Cape",
      data: [
        "Mossel Bay",
        "Cape Town City",
        "N1 City Goodwood",
        "Saldanha",
        "Stellenbosch",
      ],
    },
    {
      title: "KwaZulu-Natal",
      data: [
        "Durban Downtown",
        "Ladysmith",
        "Newcastle",
        "Pinetown",
        "Umhlanga",
        "Port Shepstone",
      ],
    },
    {
      title: "Free State",
      data: ["Bloemfontein Downtown"],
    },
    {
      title: "Eastern Cape",
      data: [
        "Queenstown",
        "Port Elizabeth Downtown",
        "East London Down Town",
        "Jeffreys Bay",
      ],
    },
    {
      title: "Limpopo",
      data: ["Hoedspruit", "Venda"],
    },
    {
      title: "Mpumalanga",
      data: ["Nelspruit Downtown", "Secunda", "Witbank", "Ermelo"],
    },
    {
      title: "North West",
      data: ["Klerksdorp", "Mafikeng", "Rustenburg"],
    },
    {
      title: "Northern Cape",
      data: ["Kathu"],
    },
  ];

  const [locationsVisible, setLocVisible] = useState(false);

  const Item = ({ title }) => (
    <TouchableOpacity
      onPress={() => {
        setLocation(title);
        setLocVisible(false);
      }}
    >
      <Text
        style={{ fontFamily: "Uber-Regular", marginBottom: 8, fontSize: 16 }}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );

  const [saving, setSaving] = useState(false);

  const uploadFiles = async () => {
    /**
    *  if (!validateEmail(profile.email)) {
      alert("Please enter a valid email address");
      return;
    }

     let missingDocs = 0;

    for (const file in documents) {
      if (documents[file] === null) {
        missingDocs = 1;
      }
    }

    if (missingDocs > 0) {
      alert(
        "One or more required documents are missing. Please upload all documents listed to proceed with submitting your application."
      );
      return;
    }

    */

    let missing = 0;

    for (const value in profile) {
      if (profile[value] == "") {
        missing = 1;
      }
    }

    if (missing == 1) {
      alert("Please fill out all fields");
      return;
    }
    if (collectionDate === "") {
      alert("Please select collection date");
      return;
    }
    if (location === "") {
      alert("Please select collection location");
      return;
    }
    setSaving(true);

    const applicationID = uuid.v4();
    const storage = getStorage();

    let links = [];

    let count = 0;

    for (const file in documents) {
      if (documents[file] === null) {
        count = count + 1;
        if (count == 4) {
          saveApplication(applicationID, links);
          break;
        }
        continue;
      }

      const storageRef = ref(
        storage,
        "documents/" + applicationID + "/" + file
      );

      let f = await fetch(documents[file]);
      const blob = await f.blob();
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          alert("Sorry, your application couldn't be submitted");
          setSaving(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            links.push({ [file]: downloadURL });
            count = count + 1;
            if (count == 4) {
              saveApplication(applicationID, links);
            }
          });
        }
      );
    }
  };

  const [location, setLocation] = useState("");

  return (
    <View style={{ backgroundColor: "#fcfaf7" }}>
      <MediaQuery maxWidth={849}>
        <View
          style={{
            flexDirection: "column",
            padding: 20,
            borderRadius: 12,
            marginBottom: 32,
          }}
        >
          <View
            style={{
              padding: 20,
              borderWidth: 1,
              borderColor: "silver",
              borderRadius: 12,
            }}
          >
            <Text style={{ fontFamily: "Uber-Bold", fontSize: 18 }}>
              {car.name}
            </Text>
            <Text style={{ fontFamily: "Uber-Light", fontSize: 16 }}>
              {car.model}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Icon color="black" name="cog-outline" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Medium" }}>
                  {car.transmission}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  marginHorizontal: 24,
                }}
              >
                <Icon color="black" name="local-gas-station" type="material" />
                <Text style={{ fontFamily: "Uber-Medium" }}>{car.fuel}</Text>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Icon color="black" name="car-outline" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Medium" }}>
                  {car.bodyType}
                </Text>
              </View>
            </View>
            <Text
              style={{
                fontFamily: "Uber-Medium",
                fontSize: 16,
                marginTop: 32,
              }}
            >
              Drive to Own
            </Text>
            <View
              style={{
                padding: 12,
                borderRadius: 8,
                borderColor: "#E2E2E2",
                alignItems: "center",
                borderWidth: 0.8,
                marginTop: 8,
              }}
            >
              <Text style={{ fontFamily: "Uber-Regular" }}>
                <Text style={{ fontFamily: "Uber-Bold" }}>
                  {numberWithCommas(parseInt(car.freeKM))}
                </Text>{" "}
                km free included
              </Text>
              <Text style={{ fontFamily: "Uber-Regular", marginVertical: 8 }}>
                <Text style={{ fontFamily: "Uber-Bold" }}>
                  R{car.pricePerKm}
                </Text>{" "}
                per extra KM
              </Text>
              <Text style={{ fontFamily: "Uber-Regular", textAlign: "center" }}>
                Own the car after driving
                <Text style={{ fontFamily: "Uber-Bold" }}>
                  {" "}
                  {numberWithCommas(parseInt(car.kmToOwn))}
                </Text>
                KM
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "Uber-Medium",
                fontSize: 16,
                marginTop: 24,
              }}
            >
              What's included?
            </Text>
            <Text
              style={{ fontFamily: "Uber-Light", fontSize: 16, marginTop: 8 }}
            >
              - Service and Maintenance{"\n"}- Insurance{"\n"}- Free{" "}
              {numberWithCommas(parseInt(car.freeKM))}KM
              {"\n"}- 24/7 Road Assistance
            </Text>
            <Divider style={{ marginVertical: 16 }} />
            <View style={{ flexDirection: "row" }}>
              <View style={{ marginRight: 16 }}>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  Monthly subscription
                </Text>
                <Text style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}>
                  Once-off activation fee
                </Text>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  Promotional discount
                </Text>
                <Text style={{ fontFamily: "Uber-Bold", marginVertical: 7 }}>
                  Total
                </Text>
              </View>
              <View>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  R{numberWithCommas(parseFloat(car.subscription).toFixed(0))}
                </Text>
                <Text style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}>
                  R{numberWithCommas(parseFloat(car.activationFee).toFixed(0))}
                </Text>

                <Text style={{ fontFamily: "Uber-Regular" }}>
                  R
                  {numberWithCommas(
                    ((parseFloat(car.activationFee) * promo) / 100).toFixed(0)
                  )}
                </Text>

                <Text style={{ fontFamily: "Uber-Bold", marginVertical: 7 }}>
                  R
                  {numberWithCommas(
                    (
                      parseFloat(car.activationFee) +
                      parseFloat(car.subscription) -
                      (parseFloat(car.activationFee) * promo) / 100
                    ).toFixed(0)
                  )}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              shadowColor: "black",
              shadowOffset: { width: 2, height: 10 },
              shadowOpacity: 0.2,
              shadowRadius: 12,
              padding: 20,
              borderRadius: 12,
              marginTop: 24,
              backgroundColor: "white",
            }}
          >
            <Text
              style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
            >
              Collection details
            </Text>

            <View
              style={{
                flexDirection: "column",
                marginTop: 16,
                marginVertical: 18,
              }}
            >
              <TouchableOpacity
                style={{ marginBottom: 8 }}
                onPress={() => {
                  setDisplayCalendar((displayCalendar) => !displayCalendar);
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: 16,
                    borderRadius: 8,
                    backgroundColor: "#191819",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    numberOfLines={1}
                    style={{
                      fontFamily: "Uber-Regular",
                      marginRight: 8,
                      color: "white",
                      maxWidth: 200,
                    }}
                  >
                    {collectionDate == ""
                      ? "Collection date"
                      : collectionDate.toDateString()}
                  </Text>
                  <Icon
                    color="white"
                    size={18}
                    name="calendar-outline"
                    type="ionicon"
                  />
                </View>
              </TouchableOpacity>

              {displayCalendar && (
                <View style={{ marginTop: 32 }}>
                  <CalendarPicker
                    minDate={new Date().addDays(2).toDateString()}
                    allowBackwardRangeSelect={false}
                    headerWrapperStyle={{ width: 300 }}
                    scaleFactor={500}
                    onDateChange={onDateChange}
                  />
                </View>
              )}
              <TouchableOpacity
                onPress={() => {
                  setLocVisible((locationsVisible) => !locationsVisible);
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: 16,
                    borderRadius: 8,
                    backgroundColor: "#191819",
                    paddingVertical: 12,
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    numberOfLines={1}
                    style={{
                      fontFamily: "Uber-Regular",
                      marginRight: 8,
                      color: "white",
                      maxWidth: 200,
                    }}
                  >
                    {location == "" ? "Collection location" : location}
                  </Text>
                  <Icon
                    color="white"
                    size={18}
                    name="location-outline"
                    type="ionicon"
                  />
                </View>
              </TouchableOpacity>
            </View>

            {locationsVisible && (
              <View
                style={{
                  padding: 16,
                  borderRadius: 12,
                  backgroundColor: "#E5E5E5",
                }}
              >
                <SectionList
                  contentContainerStyle={{
                    maxHeight: 400,
                  }}
                  sections={DATA}
                  keyExtractor={(item, index) => item + index}
                  renderItem={({ item }) => <Item title={item} />}
                  renderSectionHeader={({ section: { title } }) => (
                    <Text style={{ fontFamily: "Uber-Bold", marginBottom: 16 }}>
                      {title}
                    </Text>
                  )}
                />
              </View>
            )}

            <Text
              style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
            >
              Personal Details
            </Text>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                onChangeText={(text) => {
                  setProfile({ ...profile, name: text });
                }}
                value={profile.name}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="First and Last name "
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                onChangeText={(text) => {
                  setProfile({ ...profile, phone: text });
                }}
                value={profile.phone}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="Contact number"
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                onChangeText={(text) => {
                  setProfile({ ...profile, id: text });
                }}
                value={profile.id}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="ID (SA) / Passport Number "
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                onChangeText={(text) => {
                  setProfile({ ...profile, email: text });
                }}
                value={profile.email}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="Email address "
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                borderColor: "#F2F5F9",
                borderRadius: 8,
                paddingLeft: 12,
                alignItems: "center",
                borderWidth: 1,
                marginRight: 24,
                marginTop: 8,
              }}
            >
              <TextInput
                onChangeText={(text) => {
                  setProfile({
                    ...profile,
                    income: text
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*?)\..*/g, "$1"),
                  });
                }}
                value={profile.income}
                style={{
                  paddingVertical: 12,
                  flex: 1,
                  paddingHorizontal: 8,
                  outlineStyle: "none",
                }}
                placeholder="Gross income (in rands)"
              />
            </View>

            <Text
              style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
            >
              Documents
            </Text>
            <Text style={{ ontFamily: "Uber-Medium", marginBottom: 8 }}>
              Accepted formats: pdf, jpeg, png
            </Text>
            <TouchableOpacity onPress={() => pickImage("id")}>
              <View
                style={{
                  borderRadius: 12,
                  paddingHorizontal: 20,

                  marginVertical: 8,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingVertical: 12,
                  backgroundColor: "#F9F3F8",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Icon
                    style={{ marginRight: 7 }}
                    name="cloud-upload-outline"
                    type="ionicon"
                  />

                  <Text style={{ fontSize: 15 }}>Upload ID</Text>
                </View>
                {documents.id != null && (
                  <Icon color="green" size={18} name="check" />
                )}
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => pickImage("bankStatement")}>
              <View
                style={{
                  borderRadius: 12,
                  paddingHorizontal: 20,

                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingVertical: 12,
                  backgroundColor: "#F9F3F8",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Icon
                    style={{ marginRight: 7 }}
                    name="cloud-upload-outline"
                    type="ionicon"
                  />

                  <Text style={{ fontSize: 15 }}>
                    Add 3 months bank statement
                  </Text>
                </View>
                {documents.bankStatement != null && (
                  <Icon color="green" size={18} name="check" />
                )}
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => pickImage("payslip")}>
              <View
                style={{
                  borderRadius: 12,
                  paddingHorizontal: 20,

                  marginVertical: 8,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingVertical: 12,
                  backgroundColor: "#F9F3F8",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Icon
                    style={{ marginRight: 7 }}
                    name="cloud-upload-outline"
                    type="ionicon"
                  />

                  <Text style={{ fontSize: 15 }}>Latest payslip</Text>
                </View>
                {documents.payslip != null && (
                  <Icon color="green" size={18} name="check" />
                )}
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => pickImage("license")}>
              <View
                style={{
                  borderRadius: 12,
                  paddingHorizontal: 20,

                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingVertical: 12,
                  backgroundColor: "#F9F3F8",
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <Icon
                    style={{ marginRight: 7 }}
                    name="cloud-upload-outline"
                    type="ionicon"
                  />

                  <Text style={{ fontSize: 15 }}>Driver's license</Text>
                </View>
                {documents.license != null && (
                  <Icon color="green" size={18} name="check" />
                )}
              </View>
            </TouchableOpacity>

            <Text
              style={{
                maxWidth: 350,
                marginVertical: 16,
                fontFamily: "Uber-Medium",
              }}
            >
              * Uploading documents can take a few minutes. After clicking
              submit, please do not close this page before getting the success
              message.
            </Text>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                onPress={() => navigation.goBack()}
                icon="chevron-left"
                contentStyle={{ height: 45 }}
                mode="contained"
                color="black"
                theme={{ roundness: 8 }}
              >
                Back
              </Button>
              <Button
                contentStyle={{ height: 45 }}
                theme={{ roundness: 8 }}
                icon="check"
                loading={saving}
                mode="contained"
                onPress={() => uploadFiles()}
                color="orange"
                style={{ marginRight: 30 }}
              >
                Submit
              </Button>
            </View>
          </View>
        </View>
      </MediaQuery>
      <MediaQuery minWidth={850}>
        <ScrollView contentContainerStyle={styles.container}>
          <View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              padding: 20,
              borderWidth: 1,
              borderColor: "silver",
              borderRadius: 12,
            }}
          >
            <View
              style={{
                shadowColor: "black",
                shadowOffset: { width: 2, height: 10 },
                shadowOpacity: 0.2,
                shadowRadius: 12,
                padding: 20,
                borderRadius: 12,
                marginRight: 16,
                backgroundColor: "white",
              }}
            >
              <Text
                style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
              >
                Collection details
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 16,
                  marginVertical: 18,
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{ marginRight: 16 }}
                  onPress={() => {
                    setDisplayCalendar((displayCalendar) => !displayCalendar);
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingHorizontal: 8,
                      borderRadius: 8,
                      backgroundColor: "#191819",
                      paddingVertical: 7,
                    }}
                  >
                    <Text
                      numberOfLines={1}
                      style={{
                        fontFamily: "Uber-Regular",
                        marginRight: 8,
                        color: "white",
                        maxWidth: 200,
                      }}
                    >
                      {collectionDate == ""
                        ? "Collection date"
                        : collectionDate.toDateString()}
                    </Text>
                    <Icon
                      color="white"
                      size={18}
                      name="calendar-outline"
                      type="ionicon"
                    />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setLocVisible((locationsVisible) => !locationsVisible);
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingHorizontal: 8,
                      borderRadius: 8,
                      backgroundColor: "#191819",
                      paddingVertical: 7,
                    }}
                  >
                    <Text
                      numberOfLines={1}
                      style={{
                        fontFamily: "Uber-Regular",
                        marginRight: 8,
                        color: "white",
                        maxWidth: 200,
                      }}
                    >
                      {location == "" ? "Collection location" : location}
                    </Text>
                    <Icon
                      color="white"
                      size={18}
                      name="location-outline"
                      type="ionicon"
                    />
                  </View>
                </TouchableOpacity>
              </View>

              {displayCalendar && (
                <CalendarPicker
                  minDate={new Date().addDays(2).toDateString()}
                  allowBackwardRangeSelect={false}
                  headerWrapperStyle={{ width: 300 }}
                  scaleFactor={605}
                  onDateChange={onDateChange}
                />
              )}

              {locationsVisible && (
                <View
                  style={{
                    padding: 16,
                    borderRadius: 12,
                    backgroundColor: "#E5E5E5",
                  }}
                >
                  <SectionList
                    contentContainerStyle={{
                      maxHeight: 400,
                    }}
                    sections={DATA}
                    keyExtractor={(item, index) => item + index}
                    renderItem={({ item }) => <Item title={item} />}
                    renderSectionHeader={({ section: { title } }) => (
                      <Text
                        style={{ fontFamily: "Uber-Bold", marginBottom: 16 }}
                      >
                        {title}
                      </Text>
                    )}
                  />
                </View>
              )}

              <Text
                style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
              >
                Personal Details
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#F2F5F9",
                  borderRadius: 8,
                  paddingLeft: 12,
                  alignItems: "center",
                  borderWidth: 1,
                  flex: 1,
                  marginRight: 24,
                  marginTop: 8,
                }}
              >
                <TextInput
                  onChangeText={(text) => {
                    setProfile({ ...profile, name: text });
                  }}
                  value={profile.name}
                  style={{
                    paddingVertical: 12,
                    flex: 1,
                    paddingHorizontal: 8,
                    outlineStyle: "none",
                  }}
                  placeholder="First and Last name "
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#F2F5F9",
                  borderRadius: 8,
                  paddingLeft: 12,
                  alignItems: "center",
                  borderWidth: 1,
                  flex: 1,
                  marginRight: 24,
                  marginTop: 8,
                }}
              >
                <TextInput
                  onChangeText={(text) => {
                    setProfile({ ...profile, phone: text });
                  }}
                  value={profile.phone}
                  style={{
                    paddingVertical: 12,
                    flex: 1,
                    paddingHorizontal: 8,
                    outlineStyle: "none",
                  }}
                  placeholder="Contact number"
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "#F2F5F9",
                  borderRadius: 8,
                  paddingLeft: 12,
                  alignItems: "center",
                  borderWidth: 1,
                  flex: 1,
                  marginRight: 24,
                  marginTop: 8,
                }}
              >
                <TextInput
                  onChangeText={(text) => {
                    setProfile({ ...profile, id: text });
                  }}
                  value={profile.id}
                  style={{
                    paddingVertical: 12,
                    flex: 1,
                    paddingHorizontal: 8,
                    outlineStyle: "none",
                  }}
                  placeholder="ID (SA) / Passport Number "
                />
              </View>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    flexDirection: "row",
                    borderColor: "#F2F5F9",
                    borderRadius: 8,
                    paddingLeft: 12,
                    alignItems: "center",
                    borderWidth: 1,
                    flex: 1,
                    marginRight: 24,
                    marginTop: 8,
                  }}
                >
                  <TextInput
                    onChangeText={(text) => {
                      setProfile({ ...profile, email: text });
                    }}
                    value={profile.email}
                    style={{
                      paddingVertical: 12,
                      flex: 1,
                      paddingHorizontal: 8,
                      outlineStyle: "none",
                    }}
                    placeholder="Email address "
                  />
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    borderColor: "#F2F5F9",
                    borderRadius: 8,
                    paddingLeft: 12,
                    alignItems: "center",
                    borderWidth: 1,
                    flex: 1,
                    marginRight: 24,
                    marginTop: 8,
                  }}
                >
                  <TextInput
                    onChangeText={(text) => {
                      setProfile({
                        ...profile,
                        income: text
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1"),
                      });
                    }}
                    value={profile.income}
                    style={{
                      paddingVertical: 12,
                      flex: 1,
                      paddingHorizontal: 8,
                      outlineStyle: "none",
                    }}
                    placeholder="Gross income (in rands)"
                  />
                </View>
              </View>

              <Text
                style={{ marginTop: 24, fontFamily: "Uber-Bold", fontSize: 18 }}
              >
                Documents
              </Text>
              <Text style={{ ontFamily: "Uber-Medium", marginBottom: 8 }}>
                Accepted formats: pdf, jpeg, png
              </Text>
              <TouchableOpacity onPress={() => pickImage("id")}>
                <View
                  style={{
                    borderRadius: 12,
                    paddingHorizontal: 20,

                    marginVertical: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    backgroundColor: "#F9F3F8",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon
                      style={{ marginRight: 7 }}
                      name="cloud-upload-outline"
                      type="ionicon"
                    />

                    <Text style={{ fontSize: 15 }}>Upload ID</Text>
                  </View>
                  {documents.id != null && (
                    <Icon color="green" size={18} name="check" />
                  )}
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => pickImage("bankStatement")}>
                <View
                  style={{
                    borderRadius: 12,
                    paddingHorizontal: 20,

                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    backgroundColor: "#F9F3F8",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon
                      style={{ marginRight: 7 }}
                      name="cloud-upload-outline"
                      type="ionicon"
                    />

                    <Text style={{ fontSize: 15 }}>
                      Add 3 months bank statement
                    </Text>
                  </View>
                  {documents.bankStatement != null && (
                    <Icon color="green" size={18} name="check" />
                  )}
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => pickImage("payslip")}>
                <View
                  style={{
                    borderRadius: 12,
                    paddingHorizontal: 20,

                    marginVertical: 8,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    backgroundColor: "#F9F3F8",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon
                      style={{ marginRight: 7 }}
                      name="cloud-upload-outline"
                      type="ionicon"
                    />

                    <Text style={{ fontSize: 15 }}>Latest payslip</Text>
                  </View>
                  {documents.payslip != null && (
                    <Icon color="green" size={18} name="check" />
                  )}
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => pickImage("license")}>
                <View
                  style={{
                    borderRadius: 12,
                    paddingHorizontal: 20,

                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingVertical: 12,
                    backgroundColor: "#F9F3F8",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon
                      style={{ marginRight: 7 }}
                      name="cloud-upload-outline"
                      type="ionicon"
                    />

                    <Text style={{ fontSize: 15 }}>Driver's license</Text>
                  </View>
                  {documents.license != null && (
                    <Icon color="green" size={18} name="check" />
                  )}
                </View>
              </TouchableOpacity>

              <Text
                style={{
                  maxWidth: 350,
                  marginVertical: 16,
                  fontFamily: "Uber-Medium",
                }}
              >
                * Uploading documents can take a few minutes. After clicking
                submit, please do not close the page before getting the success
                message.
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onPress={() => navigation.goBack()}
                  icon="chevron-left"
                  contentStyle={{ height: 45 }}
                  mode="contained"
                  color="black"
                  theme={{ roundness: 8 }}
                >
                  Back
                </Button>
                <Button
                  loading={saving}
                  contentStyle={{ height: 45 }}
                  theme={{ roundness: 8 }}
                  icon="check"
                  mode="contained"
                  onPress={() => uploadFiles()}
                  color="orange"
                  style={{ marginRight: 30 }}
                >
                  Submit
                </Button>
              </View>
            </View>

            <View
              style={{
                padding: 20,
                borderRadius: 12,
                height: "100%",
              }}
            >
              <Text style={{ fontFamily: "Uber-Bold", fontSize: 18 }}>
                {car.name}
              </Text>
              <Text style={{ fontFamily: "Uber-Light", fontSize: 16 }}>
                {car.model}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 8,
                }}
              >
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Icon color="black" name="cog-outline" type="ionicon" />
                  <Text style={{ fontFamily: "Uber-Medium" }}>
                    {car.transmission}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    marginHorizontal: 24,
                  }}
                >
                  <Icon
                    color="black"
                    name="local-gas-station"
                    type="material"
                  />
                  <Text style={{ fontFamily: "Uber-Medium" }}>{car.fuel}</Text>
                </View>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Icon color="black" name="car-outline" type="ionicon" />
                  <Text style={{ fontFamily: "Uber-Medium" }}>
                    {car.bodyType}
                  </Text>
                </View>
              </View>
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  fontSize: 16,
                  marginTop: 32,
                }}
              >
                Drive to Own
              </Text>
              <View
                style={{
                  padding: 12,
                  borderRadius: 8,
                  borderColor: "#E2E2E2",
                  alignItems: "center",
                  borderWidth: 0.8,
                  marginTop: 8,
                }}
              >
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    {numberWithCommas(parseInt(car.freeKM))}
                  </Text>{" "}
                  km free included
                </Text>
                <Text style={{ fontFamily: "Uber-Regular", marginVertical: 8 }}>
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    R{car.pricePerKm}
                  </Text>{" "}
                  per extra KM
                </Text>
                <Text style={{ fontFamily: "Uber-Regular" }}>
                  Own the car after driving
                  <Text style={{ fontFamily: "Uber-Bold" }}>
                    {" "}
                    {numberWithCommas(parseInt(car.kmToOwn))}
                  </Text>
                  KM
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: "Uber-Medium",
                  fontSize: 16,
                  marginTop: 24,
                }}
              >
                What's included?
              </Text>
              <Text
                style={{ fontFamily: "Uber-Light", fontSize: 16, marginTop: 8 }}
              >
                - Service and Maintenance{"\n"}- Insurance{"\n"}- Free{" "}
                {numberWithCommas(parseInt(car.freeKM))}KM
                {"\n"}- 24/7 Road Assistance
              </Text>
              <Divider style={{ marginVertical: 16 }} />
              <View style={{ flexDirection: "row" }}>
                <View style={{ marginRight: 16 }}>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    Monthly subscription
                  </Text>
                  <Text
                    style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}
                  >
                    Once-off activation fee
                  </Text>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    Promotional discount
                  </Text>
                  <Text style={{ fontFamily: "Uber-Bold", marginVertical: 7 }}>
                    Total
                  </Text>
                </View>
                <View>
                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    R{numberWithCommas(parseFloat(car.subscription).toFixed(0))}
                  </Text>
                  <Text
                    style={{ fontFamily: "Uber-Regular", marginVertical: 7 }}
                  >
                    R
                    {numberWithCommas(parseFloat(car.activationFee).toFixed(0))}
                  </Text>

                  <Text style={{ fontFamily: "Uber-Regular" }}>
                    R
                    {numberWithCommas(
                      ((parseFloat(car.activationFee) * promo) / 100).toFixed(0)
                    )}
                  </Text>

                  <Text style={{ fontFamily: "Uber-Bold", marginVertical: 7 }}>
                    R
                    {numberWithCommas(
                      (
                        parseFloat(car.activationFee) +
                        parseFloat(car.subscription) -
                        (parseFloat(car.activationFee) * promo) / 100
                      ).toFixed(0)
                    )}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                  marginTop: 64,
                }}
              >
                <Icon name="checkmark-circle" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Light", marginLeft: 8 }}>
                  No credit checks
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <Icon name="checkmark-circle" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Light", marginLeft: 4 }}>
                  Inclusive
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 8,
                }}
              >
                <Icon name="checkmark-circle" type="ionicon" />
                <Text style={{ fontFamily: "Uber-Light", marginLeft: 4 }}>
                  Prepaid car
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </MediaQuery>
    </View>
  );
}
