import React, { useState, useEffect } from "react";

import {
  StyleSheet,
  Text,
  View,
  SafeAreaView,
  Image,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { Button } from "react-native-paper";
import { Icon } from "react-native-elements";
import {} from "react-native-web";

import MediaQuery from "react-responsive";

import { getFirestore, doc, updateDoc, getDoc } from "firebase/firestore";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
  },
});

export default function Agreement({ navigation, route }) {
  const { id } = route.params || { id: "" };

  const [loading, setLoading] = useState(false);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");

  const db = getFirestore();

  // 1,2,3 21,22,23, 31

  const ranges = [
    { num: "1", end: "st" },
    { num: "2", end: "nd" },
    { num: "3", end: "rd" },
    { num: "21", end: "st" },
    { num: "22", end: "nd" },
    { num: "23", end: "rd" },
    { num: "31", end: "st" },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [item, setItem] = useState({});

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const serialize = function (obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  const getApplication = async () => {
    setLoading(true);
    const docRef = doc(db, "applications", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setItem(docSnap.data());
    }
    setLoading(false);
  };

  const signAgreement = async () => {
    let ending = "";
    let index = ranges.findIndex(
      (a) => a.num === new Date().getDate().toString()
    );
    if (index != -1) {
      ending = ranges[index].end;
    } else {
      ending = "th";
    }
    setLoading(true);
    const docRef = doc(db, "applications", id);
    await updateDoc(docRef, {
      signed: true,
      signedAt: new Date().toDateString() + " at " + new Date().toTimeString(),
    });
    let params = {
      email: item.email,
      client: item.name,
      car: item.car.name + " " + item.car.model,
      freeKM: numberWithCommas(item.car.freeKM) + "km",
      id: item.id.toUpperCase(),
      activationfee: "R" + numberWithCommas(item.car.activationFee),
      servicefee: "R" + numberWithCommas(item.car.subscription),
      total:
        "R" +
        numberWithCommas(
          parseInt(item.car.activationFee) + parseInt(item.car.subscription)
        ),
      costperkm: "R" + item.car.pricePerKm,
      collectiondate: item.collectionDate,
      date: new Date().toDateString(),
      link: "https://drivetoown.co.za/Agreement?id=" + item.applicationID,
      clientday: new Date().getDate() + ending,
      clientmonthyear:
        months[new Date().getMonth()] + " " + new Date().getFullYear(),
      firstname: firstname,
      lastname: lastname,
      address: address,
      signature: firstname.charAt(0) + " " + lastname,
      phone: phone,
    };
    let url = "https://redzoneafrica.com/docs/signed.php?" + serialize(params);
    const response = await fetch(url);
    setLoading(false);
    setTimeout(() => {
      alert(
        "Thanks for signing the agreement! We have sent your own copy to your email address. "
      );
    }, 500);
    navigation.navigate("Home");
  };

  useEffect(() => {
    getApplication();
  }, []);

  return (
    <View style={styles.container}>
      <View
        style={{
          width: "100%",
          height: 350,
          backgroundColor: "orange",
          alignItems: "center",
          padding: 60,
          justifyContent: "center",
        }}
      >
        <Image
          resizeMode="contain"
          style={{ height: 80, width: 160 }}
          source={require("./assets/logo.png")}
        />

        <Text style={{ fontFamily: "Uber-Bold", fontSize: 32 }}>Agreement</Text>

        <Text
          style={{
            fontFamily: "Uber-Light",
            fontSize: 18,
            maxWidth: 350,
            textAlign: "center",
            marginTop: 16,
          }}
        >
          By filling in this form and clicking on 'Sign Agreement' you
          acknowledge that you have read the agreement sent to your email and
          you agree to its contents.
        </Text>
      </View>
      <MediaQuery maxWidth={500}>
        <View
          style={{
            borderRadius: 12,
            flexDirection: "column",
            paddingVertical: 20,
            alignSelf: "center",
            backgroundColor: "white",
            marginTop: -36,
            paddingHorizontal: 20,
            flex: 1,
            width: "100%",
          }}
        >
          <Text style={{ marginHorizontal: 16, fontFamily: "Uber-Regular" }}>
            First name
          </Text>
          <TextInput
            value={firstname}
            onChangeText={(text) => {
              setFirstname(text);
            }}
            placeholder="Enter your first name"
            style={{
              paddingVertical: 8,
              paddingHorizontal: 16,
              fontSize: 16,
              outlineStyle: "none",
              fontFamily: "Uber-Medium",
            }}
          />
          <View
            style={{
              width: "100%",
              height: 0.5,
              backgroundColor: "silver",
              alignSelf: "center",
              opacity: 0.6,
              marginVertical: 8,
            }}
          />
          <Text style={{ marginHorizontal: 16, fontFamily: "Uber-Regular" }}>
            Last name
          </Text>
          <TextInput
            value={lastname}
            placeholder="Enter your last name"
            style={{
              paddingVertical: 8,
              paddingHorizontal: 16,
              fontSize: 16,
              outlineStyle: "none",
              fontFamily: "Uber-Medium",
            }}
            onChangeText={(text) => {
              setLastName(text);
            }}
          />

          <View
            style={{
              width: "100%",
              height: 0.5,
              backgroundColor: "silver",
              alignSelf: "center",
              opacity: 0.6,
              marginVertical: 8,
            }}
          />
          <Text style={{ marginHorizontal: 16, fontFamily: "Uber-Regular" }}>
            Physical address
          </Text>
          <TextInput
            placeholder="Enter home address"
            value={address}
            onChangeText={(text) => {
              setAddress(text);
            }}
            style={{
              paddingVertical: 8,
              paddingHorizontal: 16,
              fontSize: 16,
              outlineStyle: "none",
              fontFamily: "Uber-Medium",
            }}
          />
          <View
            style={{
              width: "100%",
              height: 0.5,
              backgroundColor: "silver",
              alignSelf: "center",
              opacity: 0.6,
              marginVertical: 8,
            }}
          />
          <Text style={{ marginHorizontal: 16, fontFamily: "Uber-Regular" }}>
            Phone number
          </Text>
          <TextInput
            value={phone}
            onChangeText={(text) => {
              setPhone(text);
            }}
            placeholder="Add phone number"
            style={{
              paddingVertical: 8,
              paddingHorizontal: 16,
              fontSize: 16,
              outlineStyle: "none",
              fontFamily: "Uber-Medium",
            }}
          />

          <TouchableOpacity
            disabled={loading}
            onPress={() => {
              if (
                firstname == "" ||
                lastname == "" ||
                address == "" ||
                phone == ""
              ) {
                alert("Please fill out all fields");
                return;
              }
              signAgreement();
            }}
            style={{
              backgroundColor: "black",
              padding: 12,
              borderRadius: 24,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              marginHorizontal: 20,
              marginTop: 16,
              marginBottom: 24,
            }}
          >
            {loading ? (
              <ActivityIndicator color="white" />
            ) : (
              <Icon color="white" name="pencil-outline" type="ionicon" />
            )}
            <Text
              style={{
                fontFamily: "Uber-Bold",
                fontSize: 16,
                color: "white",
                marginLeft: 16,
              }}
            >
              Sign Agreement
            </Text>
          </TouchableOpacity>
        </View>
      </MediaQuery>
      <MediaQuery minWidth={501}>
        <View
          style={{
            borderRadius: 12,
            flexDirection: "column",
            paddingVertical: 20,
            width: 400,
            alignSelf: "center",
            backgroundColor: "white",
            marginTop: -36,
          }}
        >
          <Text style={{ marginHorizontal: 16, fontFamily: "Uber-Regular" }}>
            First name
          </Text>
          <TextInput
            value={firstname}
            onChangeText={(text) => {
              setFirstname(text);
            }}
            placeholder="Enter your first name"
            style={{
              paddingVertical: 8,
              paddingHorizontal: 16,
              fontSize: 16,
              outlineStyle: "none",
              fontFamily: "Uber-Medium",
            }}
          />
          <View
            style={{
              width: "100%",
              height: 0.5,
              backgroundColor: "silver",
              alignSelf: "center",
              opacity: 0.6,
              marginVertical: 8,
            }}
          />
          <Text style={{ marginHorizontal: 16, fontFamily: "Uber-Regular" }}>
            Last name
          </Text>
          <TextInput
            value={lastname}
            placeholder="Enter your last name"
            style={{
              paddingVertical: 8,
              paddingHorizontal: 16,
              fontSize: 16,
              outlineStyle: "none",
              fontFamily: "Uber-Medium",
            }}
            onChangeText={(text) => {
              setLastName(text);
            }}
          />

          <View
            style={{
              width: "100%",
              height: 0.5,
              backgroundColor: "silver",
              alignSelf: "center",
              opacity: 0.6,
              marginVertical: 8,
            }}
          />
          <Text style={{ marginHorizontal: 16, fontFamily: "Uber-Regular" }}>
            Physical address
          </Text>
          <TextInput
            placeholder="Enter home address"
            value={address}
            onChangeText={(text) => {
              setAddress(text);
            }}
            style={{
              paddingVertical: 8,
              paddingHorizontal: 16,
              fontSize: 16,
              outlineStyle: "none",
              fontFamily: "Uber-Medium",
            }}
          />
          <View
            style={{
              width: "100%",
              height: 0.5,
              backgroundColor: "silver",
              alignSelf: "center",
              opacity: 0.6,
              marginVertical: 8,
            }}
          />
          <Text style={{ marginHorizontal: 16, fontFamily: "Uber-Regular" }}>
            Phone number
          </Text>
          <TextInput
            value={phone}
            onChangeText={(text) => {
              setPhone(text);
            }}
            placeholder="Add phone number"
            style={{
              paddingVertical: 8,
              paddingHorizontal: 16,
              fontSize: 16,
              outlineStyle: "none",
              fontFamily: "Uber-Medium",
            }}
          />

          <TouchableOpacity
            disabled={loading}
            onPress={() => {
              if (
                firstname == "" ||
                lastname == "" ||
                address == "" ||
                phone == ""
              ) {
                alert("Please fill out all fields");
                return;
              }
              signAgreement();
            }}
            style={{
              backgroundColor: "black",
              padding: 12,
              borderRadius: 24,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              marginHorizontal: 20,
              marginTop: 16,
            }}
          >
            {loading ? (
              <ActivityIndicator color="white" />
            ) : (
              <Icon color="white" name="pencil-outline" type="ionicon" />
            )}
            <Text
              style={{
                fontFamily: "Uber-Bold",
                fontSize: 16,
                color: "white",
                marginLeft: 16,
              }}
            >
              Sign Agreement
            </Text>
          </TouchableOpacity>
        </View>
      </MediaQuery>
    </View>
  );
}
