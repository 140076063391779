import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { Icon } from "react-native-elements";

import { TextValues } from "../../../constants/Strings";

const styles = StyleSheet.create({});

// car name, cost km, transmission, fuel, type
// <a href="https://www.freepnglogos.com/pics/mercedes" title="Image from freepnglogos.com"><img src="https://www.freepnglogos.com/uploads/mercedes-png/grey-mercedes-benz-class-coupe-car-png-image-pngpix-27.png" width="200" alt="grey mercedes benz class coupe car png image pngpix" /></a>
export default function FAQList() {
  const renderItem = ({ item, index }) => (
    <View
      style={{
        backgroundColor: "white",
        padding: 24,
        borderRadius: 12,
        flexDirection: "column",
        marginVertical: 24,
        elevation: 16,
        shadowColor: "orange",
        shadowOffset: { width: 2, height: 10 },
        shadowOpacity: 0.1,
        shadowRadius: 24,
        width: "48%",
      }}
    >
      <Text style={{ fontFamily: "Uber-Bold", fontSize: 18 }}>
        {item.question}
      </Text>
      <Text
        style={{
          fontFamily: "Uber-Light",
          fontSize: 18,
          marginTop: 8,
          marginBottom: 16,
        }}
      >
        {item.answer}
      </Text>
      <View
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          borderBottomEndRadius: 12,
          borderTopStartRadius: 12,
          paddingHorizontal: 16,
          paddingVertical: 12,
          backgroundColor: "#fff0d6",
        }}
      >
        <Text style={{ fontFamily: "Uber-Bold" }}>{index + 1}</Text>
      </View>
    </View>
  );
  return (
    <View
      style={{
        flexDirection: "column",
        paddingVertical: 24,
        paddingHorizontal: 20,
      }}
    >
      <FlatList
        numColumns={2}
        contentContainerStyle={{ marginBottom: 32, flex: 1 }}
        data={TextValues.faqs}
        keyExtractor={(item, index) => {
          return index.toString();
        }}
        renderItem={renderItem}
      />
    </View>
  );
}
