import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View, FlatList } from "react-native";
import MediaQuery from "react-responsive";
import { useState, useEffect } from "react";
import { Button } from "react-native-paper";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { TouchableOpacity } from "react-native-web";
import AsyncStorage from "@react-native-async-storage/async-storage";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    flexDirection: "column",
  },
});

export default function Applications({ navigation }) {
  const db = getFirestore();

  const [applications, setApplications] = useState([]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getApplications = async () => {
    const querySnapshot = await getDocs(collection(db, "applications"));
    const temp = [];
    querySnapshot.forEach((doc) => {
      temp.push({ ...doc.data(), applicationID: doc.id });
    });
    setApplications(temp);
  };

  useEffect(() => {
    getApplications();
  });

  const renderItem = ({ item }) => (
    <View style={{ marginVertical: 12 }}>
      <Text style={{ fontFamily: "Uber-Medium" }}>{item.name || ""}</Text>
      {item.car != null && (
        <Text style={{ fontFamily: "Uber-Bold" }}>
          {item.car.name + " " + item.car.model}
        </Text>
      )}
      <TouchableOpacity
        onPress={async () => {
          await AsyncStorage.setItem("@client", JSON.stringify(item));
          await AsyncStorage.setItem("@car", JSON.stringify(item.car));
          navigation.navigate("Application");
        }}
        style={{ marginTop: 4 }}
      >
        <Text style={{ color: "blue" }}>View application</Text>
      </TouchableOpacity>
    </View>
  );

  const [tab, setTab] = useState("Pending");

  return (
    <View style={styles.container}>
      <Text
        style={{
          marginTop: 24,
          fontSize: 20,
          fontWeight: "bold",
          marginTop: 32,
          marginHorizontal: 32,
        }}
      >
        Drive to Own{"\n"}
        <Text style={{ fontSize: 16, opacity: 0.7 }}>Applications</Text>
      </Text>

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginHorizontal: 16,
          marginTop: 24,
        }}
      >
        <TouchableOpacity
          onPress={() => setTab("Pending")}
          style={{ marginHorizontal: 16 }}
        >
          <Text style={{ color: tab === "Pending" ? "orange" : "black" }}>
            Pending
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => setTab("Approved")}
          style={{ marginHorizontal: 16 }}
        >
          <Text style={{ color: tab === "Approved" ? "orange" : "black" }}>
            Approved
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => setTab("Declined")}
          style={{ marginHorizontal: 16 }}
        >
          <Text style={{ color: tab === "Declined" ? "orange" : "black" }}>
            Declined
          </Text>
        </TouchableOpacity>
      </View>
      <View style={{ flex: 1, marginTop: 32 }}>
        {applications.length > 0 && (
          <FlatList
            contentContainerStyle={{ paddingHorizontal: 32, paddingBottom: 64 }}
            data={
              tab === "Pending"
                ? applications.filter((a) => {
                    return a.approved == null;
                  })
                : tab === "Approved"
                ? applications.filter((a) => {
                    return a.approved === true;
                  })
                : applications.filter((a) => {
                    return a.approved === false;
                  })
            }
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
          />
        )}
      </View>
    </View>
  );
}
